import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from 'antd';
import { ToolOutlined } from '@ant-design/icons';

import { useGetPaginatedStoreAccountsForAdmin } from 'apis/adminExternal';

import { useFetchConstant } from 'hooks/constants';
import { useLocationQuery } from 'hooks/router';
import { useIsLoadings } from 'hooks/utils';

import { formatToDateString } from 'utils/date';
import { getLabelOfConstant } from 'utils/general';
import { get403Route } from 'utils/routes';
import { constructColumn, constructColumnFilterRadio, constructColumnFilterSearch, handleOnAggregationTableChange } from 'utils/table/table';

import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import StatusIndicator from 'components/StatusIndicator/StatusIndicator';
import TextButton from 'components/TextButton/TextButton';
import Title from 'components/Title/Title';

import AccountDetailsModal from './components/AccountDetailsModal/AccountDetailsModal';

import { SubscriptionPeriodText } from './AdminAccountValidation.styles';

/* ================================================= Local Functions ================================================= */
const constructColumns = (
  accountStatuses,
  accountStatusesConst,
  subscriptionTypes,
  subscriptionTypesConst,
  subscriptionPeriods,
  handleOnManageClick
) => [
  {
    ...constructColumn('Sign Up Date', 'createdAt', { hasAggregationSorter: true, width: '10%' }),
    render: date => formatToDateString(date, 'LLL')
  },
  {
    ...constructColumn('Store Name', 'name', {
      width: '17%'
    }),
    ...constructColumnFilterSearch('name', 'name', { hasAggregationFilter: true })
  },
  {
    ...constructColumn('Short N.', 'shortName', { hasAggregationSorter: true }),
    ...constructColumnFilterSearch('shortName', 'shortName', { hasAggregationFilter: true })
  },
  {
    ...constructColumn('Exp. Date', 'expiryDate', { hasAggregationSorter: true, width: '8%' }),
    render: date => formatToDateString(date)
  },
  {
    ...constructColumn('Plan', 'subscriptionType', { hasAggregationSorter: true }),
    ...constructColumnFilterRadio('subscriptionType', subscriptionTypes),
    render: (subscriptionType, record) => (
      <>
        <span>{getLabelOfConstant(subscriptionType, subscriptionTypes) || '-'}</span>
        {subscriptionType === subscriptionTypesConst.BUSINESS.code && record.subscriptionPeriod && (
          <>
            <br />
            <SubscriptionPeriodText>{getLabelOfConstant(record.subscriptionPeriod, subscriptionPeriods) || ''}</SubscriptionPeriodText>
          </>
        )}
      </>
    )
  },
  {
    ...constructColumn('Status', 'status', { hasAggregationSorter: true, width: '8%' }),
    ...constructColumnFilterRadio('status', accountStatuses),
    render: (status, record) => (
      <>
        <StatusIndicator isActive={accountStatusesConst && record.status === accountStatusesConst.ACTIVE.code} />{' '}
        {getLabelOfConstant(status, accountStatuses)}
      </>
    )
  },
  {
    ...constructColumn('Sellers', 'operatorInfos', { hasAggregationSorter: true, width: '18%' }),
    ...constructColumnFilterSearch('operatorInfos', 'Search name or contact', { hasAggregationFilter: true })
  },
  {
    ...constructColumn('Source', ['signupSource'], { width: '5%' })
  },
  {
    ...constructColumn('Hear From', ['hearAboutUsSource'], { width: '5%' }),
    render: text => (!!text ? text.charAt(0).toUpperCase() + text.slice(1) : '-')
  },
  {
    ...constructColumn('Actions', 'action'),
    render: (text, record) => {
      return (
        <>
          <TextButton icon={<ToolOutlined />} onClick={() => handleOnManageClick(record._id)} text="Manage" />
        </>
      );
    }
  }
];

const useFetchConstants = () => {
  const { selection: accountStatuses, data: accountStatusesConst, isLoading: isAccountStatusesLoading } = useFetchConstant('accountStatuses');
  const { selection: subscriptionTypes, data: subscriptionTypesConst, isLoading: isSubscriptionTypesLoading } = useFetchConstant('subscriptionTypes');
  const { selection: subscriptionPeriods, isLoading: isSubscriptionPeriodsLoading } = useFetchConstant('subscriptionPeriods');
  const { selection: locales, isLoading: isLocalesLoading } = useFetchConstant('locales');

  const { isLoading: isFetchingConstants } = useIsLoadings([
    isAccountStatusesLoading,
    isSubscriptionTypesLoading,
    isSubscriptionPeriodsLoading,
    isLocalesLoading
  ]);

  return { isFetchingConstants, accountStatuses, accountStatusesConst, subscriptionTypes, subscriptionTypesConst, subscriptionPeriods, locales };
};

/* ================================================= Main Component ================================================= */
const AdminAccountValidation = () => {
  const history = useHistory();
  const { token } = useLocationQuery();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedStoreId, setSelectedStoreId] = useState('');
  const [query, setQuery] = useState({ currentPage: 1, limit: 10 });

  const {
    isFetchingConstants,
    accountStatuses,
    accountStatusesConst,
    subscriptionTypes,
    subscriptionTypesConst,
    subscriptionPeriods,
    locales
  } = useFetchConstants();

  const {
    isLoading: isStoreAccountsLoading,
    paginatedData: storeAccounts,
    total,
    refetch: refetchStoreAccounts
  } = useGetPaginatedStoreAccountsForAdmin({ ...query, extraQuery: { token } }, () => history.push(get403Route().path));

  const { isLoading } = useIsLoadings([isFetchingConstants, isStoreAccountsLoading]);

  const handleOnManageClick = selectedStoreId => {
    setSelectedStoreId(selectedStoreId);
    setIsModalVisible(true);
  };

  const handleOnModalOkClick = () => {
    setIsModalVisible(false);
  };

  const handleOnModalCancelClick = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <FullWidthContainer style={{ padding: '48px' }}>
        <Title>Seller Accounts</Title>
        <Table
          size="small"
          loading={isLoading}
          rowKey={record => record._id}
          dataSource={storeAccounts}
          columns={constructColumns(
            accountStatuses,
            accountStatusesConst,
            subscriptionTypes,
            subscriptionTypesConst,
            subscriptionPeriods,
            handleOnManageClick
          )}
          scroll={{ x: 1000 }}
          pagination={{ total }}
          onChange={(pagination, filters, sorter) => handleOnAggregationTableChange({ pagination, filters, sorter }, setQuery)}
        />
      </FullWidthContainer>

      {isModalVisible && (
        <AccountDetailsModal
          key={selectedStoreId}
          selectedStoreId={selectedStoreId}
          token={token}
          locales={locales}
          refetchStoreAccounts={refetchStoreAccounts}
          onModalCancelClick={handleOnModalCancelClick}
          onModalOkClick={handleOnModalOkClick}
        />
      )}
    </>
  );
};
export default AdminAccountValidation;
