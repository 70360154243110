import { useCustomPaginatedQuery, useCustomQuery, useRefetchQuery } from 'hooks/reactQuery';

import api from './apiHelper';

// ================================================== Create
export const postCreateOrder = payload => {
  return api.post('/order', payload);
};

export const postCreateLiveOrder = payload => {
  return api.post('/order/live', payload);
};

export const postGetOrderSummary = payload => {
  return api.post('/order/summary', payload);
};

export const postCreateOrderPayment = (orderId, payload) => {
  return api.post(`/order/${orderId}/payment`, payload);
};

export const postSendOrderCheckoutLinkViaEmail = orderId => {
  return api.post(`/order/${orderId}/email-checkout-link`);
};

export const postCreateStoreOrderTags = payload => {
  return api.post('/order/tags', payload);
};

// ================================================== Get
export const useGetOrders = (query, onError) => {
  const getOrders = async (key, query) => {
    return api.get(`/order/paginated`, {
      params: { ...query }
    });
  };

  return useCustomPaginatedQuery('orders', getOrders, query, {
    onError
  });
};

export const useGetOrdersByPostId = (postId, query, { onError, throttleRefetchTimeInMs } = {}) => {
  const getOrders = async (key, query) => {
    return api.get(`/order/paginated/post/${postId}`, {
      params: { ...query }
    });
  };

  return useCustomPaginatedQuery('orders', getOrders, query, {
    enabled: !!postId,
    onError,
    extraKeys: ['post', postId],
    throttleRefetchTimeInMs
  });
};

export const useGetOrdersForBulkPrint = orderIds => {
  const getOrdersForBulkPrint = async key => {
    return api.get(`/order/bulk-print/`, {
      params: { ids: orderIds.join(',') }
    });
  };

  return useCustomQuery('ordersForBulkPrint', [], getOrdersForBulkPrint, {
    enabled: !!orderIds
  });
};

export const useGetOrderDetails = (orderId, query = {}, onError) => {
  const getOrderDetails = async () => {
    return api.get(`/order/${orderId}`, {
      params: { ...query }
    });
  };
  return useCustomQuery('orderDetails', [orderId, query], getOrderDetails, {
    onError,
    enabled: !!orderId
  });
};

export const useGetOrderPayments = (orderId, query = {}, onError) => {
  const getOrderPayments = async () => {
    return api.get(`/order/${orderId}/payment`, {
      params: { ...query }
    });
  };

  return useCustomQuery('orderPayments', [orderId, query], getOrderPayments, {
    onError,
    enabled: !!orderId
  });
};

export const useGetOrderPaymentDetails = (orderId, paymentId, onError) => {
  const getOrderPaymentDetails = async () => {
    return api.get(`/order/${orderId}/payment/${paymentId}`);
  };

  return useCustomQuery('orderPaymentDetails', [orderId, paymentId], getOrderPaymentDetails, {
    onError,
    enabled: !!orderId && !!paymentId
  });
};

export const useGetStoreOrderTagSuggestions = (keyword, { shouldFetch = true } = {}) => {
  const getStoreOrderTagSuggestions = async () => {
    return api.get(`/order/tags/suggestions`, {
      params: { searchKeyword: keyword }
    });
  };

  return useCustomQuery('storeOrderTagSuggestions', [keyword], getStoreOrderTagSuggestions, {
    shouldDefaultEmptyObject: false,
    enabled: !!shouldFetch
  });
};

export const useRefetchOrders = () => {
  const { refetch } = useRefetchQuery(['orders']);

  return refetch;
};

export const useRefetchOrderDetails = () => {
  const { refetch } = useRefetchQuery(['orderDetails']);

  return refetch;
};

export const useRefetchOrderPayments = () => {
  const { refetch } = useRefetchQuery(['orderPayments']);

  return refetch;
};

export const useRefetchPostOrders = () => {
  const { refetch } = useRefetchQuery(['orders', 'post']);

  return refetch;
};

export const getMergedOrdersSummary = ({ mainOrderNumber, subOrderNumber }) => {
  return api.get(`/order/merge/summary`, { params: { mainOrderNumber, subOrderNumber } });
};

export const getOrdersCSVData = query => {
  return api.get(`/order/csv`, { params: query });
};

export const getShipmentsTemplate = query => {
  return api.get(`/order/csv/shipment`, { params: query });
};

export const getPaidOrdersCSVData = query => {
  return api.get(`/order/csv/paid-order`, { params: query });
};

export const getOrderProductsCSVData = query => {
  return api.get(`/order/products/csv`, { params: query });
};

export const getOrderPaymentsCSVDate = query => {
  return api.get(`/order/payments/csv`, { params: query });
};

// ================================================== Patch
export const patchUpdateOrdersShipmentDetails = payload => {
  return api.patch(`/order/bulk/shipment`, payload);
};

export const patchSplitBulkOrders = payload => {
  return api.patch(`/order/bulk/split`, payload, { timeout: 120000 }); // Wait longer as bulk split can take awhile
};

export const patchOrder = (orderId, payload) => {
  return api.patch(`/order/${orderId}`, payload);
};

export const patchOrderStatus = (orderId, payload) => {
  return api.patch(`/order/${orderId}/status`, payload);
};

export const patchOrderShipment = (orderId, payload) => {
  return api.patch(`/order/${orderId}/shipment`, payload);
};

export const patchCancelOrder = (orderId, payload) => {
  return api.patch(`/order/${orderId}/cancel`, payload);
};

export const patchOrderMerging = (orderId, { subOrderId }) => {
  return api.patch(`order/${orderId}/merge`, { subOrderId });
};

export const patchOrderSplit = (orderId, { newStatus, orderProductIdsToSplit }) => {
  return api.patch(`/order/${orderId}/split`, { newStatus, orderProductIdsToSplit });
};

export const patchConfirmPaymentOrder = (orderId, payload) => {
  return api.patch(`/order/${orderId}/confirm-payment`, payload);
};

export const patchOrderManualPayment = (orderId, paymentId, payload) => {
  return api.patch(`order/${orderId}/payment/${paymentId}`, payload);
};

export const patchOrderPrintedStatus = payload => {
  return api.patch(`order/printed`, payload);
};

// ================================================== Delete
export const deleteOrder = orderId => {
  return api.delete(`order/${orderId}`);
};

export const deleteOrderManualPayment = (orderId, paymentId) => {
  return api.delete(`order/${orderId}/payment/${paymentId}`);
};
