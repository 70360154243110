import { guard } from 'utils/general';
import { IS_DEV } from 'config/env';

/* ----------------------------General Constants---------------------------- */
/* ---------------The constants that generally use everywhere--------------- */
// Mobile Max Width
export const MOBILE_MAX_WIDTH = 768;

// Currency
export const CURRENCY_FORMAT = {
  MYR: {
    separator: ',',
    decimal: 2,
    baseCurrencyMultiplier: 1
  },
  SGD: {
    separator: ',',
    decimal: 2,
    baseCurrencyMultiplier: 1
  },
  THB: {
    separator: ',',
    decimal: 2,
    baseCurrencyMultiplier: 10
  },
  VND: {
    separator: '.',
    decimal: 0,
    baseCurrencyMultiplier: 1000
  },
  PHP: {
    separator: ',',
    decimal: 0,
    baseCurrencyMultiplier: 10
  },
  INR: {
    separator: ',',
    decimal: 0,
    baseCurrencyMultiplier: 10
  },
  IDR: {
    separator: '.',
    decimal: 0,
    baseCurrencyMultiplier: 1000
  },
  TWD: {
    separator: ',',
    decimal: 0,
    baseCurrencyMultiplier: 10
  },
  LAK: {
    separator: ',',
    decimal: 0,
    baseCurrencyMultiplier: 1000
  },
  USD: {
    separator: ',',
    decimal: 2,
    baseCurrencyMultiplier: 1
  },
  GBP: {
    separator: ',',
    decimal: 2,
    baseCurrencyMultiplier: 1
  }
};

// Date format
export const DATE_FORMAT = 'YYYY-MM-DD';
export const FORMAL_DATE_FORMAT = 'DD-MM-YYYY';
export const MONTH_FORMAT = `YYYY-MM`;
export const DATE_DATE_TIME_FORMAT = `${DATE_FORMAT} HH:mm`;
export const DATE_TIME_WITH_PM_AM = `${DATE_FORMAT} hh:mm a`;
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_WITH_DAY_FULL_FORMAT = 'llll';

// Form Label
export const LABEL_SIZE_XXLARGE = 'xxlarge';
export const LABEL_SIZE_XLARGE = 'xlarge';
export const LABEL_SIZE_LARGE = 'large';
export const LABEL_SIZE_REGULAR = 'regular';
export const LABEL_SIZE_SMALL = 'small';

// API Aggregation Endpoint Query
export const DEFAULT_PAGINATION_QUERY = { currentPage: 1, limit: 10 };

// Regex
export const REGEX_NUMBER_N_DOT = /[^0-9.]/g;
export const REGEX_NUMBER = /^\d*(\.\d*)?$/;
export const CONTACT_NUMBER_REGEX = /^[+0-9][-\s0-9]*$/;
export const EMAIL_ADDRESS_REGEX = /^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$/;
export const PRICE_REGEX = /^\d*(\.\d{0,2})?$/;
export const PASSWORD_REGEX = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,20}$/;
export const USERNAME_REGEX = /^[a-zA-Z0-9._-]{6,20}$/;
export const NRIC_REGEX = /^[0-9]{12}$/;
export const PASSCODE_REGEX = /^\d{4,6}$/;
export const KEYWORD_REGEX = /^[\u3000\u0E80-\u0EFF\u3400-\u4DBF\u4E00-\u9FFF\w\d.-]*$/gu;
export const LUCKY_DRAW_KEYWORD_REGEX = /^[\u3000\u3400-\u4DBF\u4E00-\u9FFF\w\d\s]*$/g;
export const DOMAIN_REGEX = /^(?!-)[\w\d-]{2,19}[\w\d]$/g; // A hack for lack of support on negative lockbehind from safari
export const NO_WHITESPACE_REGEX = /^\S*$/;
export const NON_LATIN_CHAR_REGEX = /[^a-zA-Z0-9!@#$%^&*_+\-=[\]{};':"\\|,.<>?$|\s+]+/gm;
export const CAPITAL_ALPHANUMERIC_MAX_4_CHARS = /^[a-zA-Z0-9&-]{1,4}$/g;
export const BREAK_LINE_REGEX = /\r\n|\r|\n/g; // This supports linux. windows, and old macs break lines

// Toggle Radio Buttons
export const constructGeneralToggleSelection = () => {
  return [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ];
};

export const FORM_OTHERS_CODE = 'others';

// Roles
// NOTES: This contants will need be sync with BE constants. Currently we need to hardcode both places
export const ROLE_RESERVATION_OPERATOR = 'reservationOperator';
export const ROLE_ADMIN = 'admin';
export const ROLE_SUPER_ADMIN = 'superAdmin';

// Login Error
export const LOGIN_ERROR_INVALID_SESSION = 'invalid-session';

// Delete Shipment order actions
export const DELETE_SHIPMENT_ORDER_ACTION_SET_TO_CANCEL = 'cancel';
export const DELETE_SHIPMENT_ORDER_ACTION_SET_TO_COMPLETE = 'complete';
// Get linked orders shipment actions
export const SHIPMENT_ACTION_DELETE = 'delete';
export const SHIPMENT_ACTION_DEACTIVATE = 'deactivate';

/* ----------------------------Specific Constants---------------------------- */
/* ---------------The constants that generally use in one page--------------- */
// =========================== Overview
export const PRODUCT_SOLD_SORT_TYPE = {
  LATEST: { value: 'latest', label: 'Sort by Latest Sold', transKey: 'overview-product-sold-sort-type-latest' },
  ALPHABET: { value: 'alphabet', label: 'Sort by A-Z', transKey: 'overview-product-sold-sort-type-alphabet' }
};
// =========================== Settings
// --------------------------- Tabs
export const settingTabsObj = {
  SHIPMENT: {
    code: 'shipment',
    label: 'Shipment',
    isDefault: true
  },
  MICROSITE: {
    code: 'microsite',
    label: 'Microsite'
  },
  PROMOTION: {
    code: 'promotion',
    label: 'Promotion'
  }
};
export const promotionDetailsTabsObj = {
  GENERAL: {
    code: 'general',
    label: 'General',
    isDefault: true
  },
  CUSTOMER: {
    code: 'customer',
    label: 'customer'
  }
};
export const reportingTabsObj = {
  ORDER_PURCHASE_DATE: {
    code: 'orderPurchaseDate',
    label: 'Order Purchase Date'
  },
  PRODUCT_PURCHASE_DATE: {
    code: 'productPurchaseDate',
    label: 'Product Purchase Date'
  }
};
export const allSettingTabs = Object.values(settingTabsObj).map(tab => tab.code);
export const defaultSettingTab = Object.values(settingTabsObj).find(tab => tab.isDefault).code;
// =========================== Product
// --------------------------- CSV Header
export const PRODUCT_CSV_HEADERS = [
  { label: 'Name', key: 'label' },
  { label: 'Variances', key: 'varianceLabel' },
  { label: 'Type', key: 'type' },
  { label: 'Keyword', key: 'keyword' },
  { label: 'Status', key: 'status' },
  { label: 'Inventory', key: 'inventory' },
  { label: 'Original Inventory', key: 'oriInventory' },
  { label: 'Selling Price', key: 'price' },
  { label: 'Original Price', key: 'oriPrice' },
  { label: 'Cost', key: 'cost' },
  { label: 'Weight (g)', key: 'weight.amount' },
  { label: 'SKU', key: 'sku' },
  { label: 'Cover Photo', key: 'coverPhotoUrl' },
  { label: 'Photos', key: 'photoFileUrls' },
  { label: 'Description', key: 'description' }
];
// =========================== Product Details
// --------------------------- Tabs
export const productDetailsTabsObj = {
  GENERAL: {
    code: 'general',
    label: 'General',
    isDefault: true
  },
  MICROSITE: {
    code: 'microsite',
    label: 'Microsite'
  },
  BULK_PURCHASE: {
    code: 'bulkPurchase',
    label: 'Bulk Purchase'
  },
  SHIPMENT: {
    code: 'shipment',
    label: 'Shipment'
  }
};
export const allProductDetailsTab = Object.values(productDetailsTabsObj).map(tab => tab.code);
export const defaultProductDetailsTab = Object.values(productDetailsTabsObj).find(tab => tab.isDefault).code;

// =========================== Order
// --------------------------- CSV Report Types
export const DATE_FILTER_TYPE = {
  ORDER_CREATED_AT: { label: 'Order created date', transKey: 'order-csv-download-date-filter-type-order-created-at', value: 'orderCreatedAt' },
  PRODUCT_PURCHASE_DATE: {
    label: 'Product purchase date',
    transKey: 'order-csv-download-date-filter-type-product-purchase-date',
    value: 'productPurchaseDate'
  }
};

export const REPORT_TYPES = {
  ORDER: { label: 'Orders', transKey: 'order-csv-download-report-type-order', value: 'orders' },
  PRODUCTS_SOLD: { label: 'Products Sold', transKey: 'order-csv-download-report-type-products-sold', value: 'productsSold' }
};

export const REPORT_ORDER_FIELDS_TYPE = {
  FULL: { label: 'Full', transKey: 'order-csv-download-report-order-fields-type-full', value: 'full' },
  PACKAGING: { label: 'Packaging Only', transKey: 'order-csv-download-report-order-fields-type-packaging', value: 'packaging' }
};

// --------------------------- CSV Headers
export const ORDER_FULL_CSV_HEADERS = [
  { label: 'Order Number', key: 'orderNumber' },
  { label: 'Purchase Date', key: 'purchasedDate' },
  { label: 'Status', key: 'status' },
  { label: 'Checkout Date', key: 'checkoutDate' },
  { label: 'Product Keyword', key: 'product.keyword' },
  { label: 'Product SKU', key: 'product.sku' },
  { label: 'Product Name', key: 'product.name' },
  { label: 'Product Qty', key: 'product.quantity' },
  { label: 'Product Unit Price', key: 'product.unitPrice' },
  { label: 'Product Total Price', key: 'product.totalPrice' },
  { label: 'Product Unit Cost', key: 'product.unitCost' },
  { label: 'Product Total Cost', key: 'product.totalCost' },
  { label: 'Product Total Profit', key: 'product.profit' },
  { label: 'Product Purchase Date', key: 'product.purchasedDate' },
  { label: 'Total Amount', key: 'totalAmount' },
  { label: 'Subtotal', key: 'subtotalPrice' },
  { label: 'Balance Amount', key: 'balanceAmount' },
  { label: 'Shipping Fee', key: 'shippingFee' },
  { label: 'Extra Fee', key: 'extraFeeAmount' },
  { label: 'Extra Fee Remarks', key: 'extraFeeRemarks' },
  { label: 'Discount', key: 'discountAmount' },
  { label: 'Discount Remarks', key: 'discountRemarks' },
  { label: 'Promo Total', key: 'promotionDiscount' },
  { label: 'Promo Code Applied', key: 'promoCodesApplied' },
  { label: 'Voucher Code Applied', key: 'vouchersApplied' },
  { label: 'Point Discount', key: 'pointDiscount' },
  { label: 'Buyer Name', key: 'customer.name' },
  { label: 'Buyer FB Name', key: 'customer.fbName' },
  { label: 'Buyer Contact', key: 'customer.contact' },
  { label: 'Recipient Name', key: 'shipment.recipientName' },
  { label: 'Recipient Contact', key: 'shipment.recipientContact' },
  { label: 'Recipient Address', key: 'shipment.address.fullAddress' },
  { label: 'Zipcode', key: 'shipment.address.zipcode' },
  { label: 'State', key: 'shipment.address.state' },
  { label: 'Shipping Method', key: 'shipment.method' },
  { label: 'Shipping Remarks', key: 'shipment.remarks' },
  { label: 'Order Remarks', key: 'remarks' },
  { label: 'Live Streamers', key: 'product.liveStreamers' },
  { label: 'Live Streamer Remarks', key: 'product.liveStreamerRemarks' },
  { label: 'Payment Method', key: 'payment.method' }
];

export const ORDER_PACKAGING_CSV_HEADERS = [
  { label: 'Order Number', key: 'orderNumber' },
  { label: 'Purchase Date', key: 'purchasedDate' },
  { label: 'Status', key: 'status' },
  { label: 'Product Keyword', key: 'product.keyword' },
  { label: 'Product SKU', key: 'product.sku' },
  { label: 'Product Name', key: 'product.name' },
  { label: 'Product Purchase Date', key: 'product.purchasedDate' },
  { label: 'Product Qty', key: 'product.quantity' },
  { label: 'Buyer Name', key: 'customer.name' },
  { label: 'Buyer FB Name', key: 'customer.fbName' }
];

// =========================== Paid Order CSVß
// =========================== Order's Product
// --------------------------- CSV Headers
export const ORDER_PRODUCTS_CSV_HEADERS = [
  { label: 'Product Name', key: 'label' },
  { label: 'Keyword', key: 'matchingKeyword' },
  { label: 'SKU', key: 'sku' },
  { label: 'Quantity Sold', key: 'quantitySold' },
  { label: 'Inventory Left', key: 'inventory' },
  { label: 'Total Price', key: 'totalPrice' }
];

// --------------------------- CSV Headers
export const PAID_ORDER_CSV_HEADERS = [
  { label: 'Order Number', key: 'orderNumber' },
  { label: 'Recipient Info', key: 'recipientInfo' },
  { label: 'Tracking No', key: 'trackingNo' },
  { label: 'Courier', key: 'courier' },
  { label: 'Tracking Link', key: 'trackingLink' }
];

// =========================== Order's Shipment CSV
// --------------------------- Shipment Type
export const SHIPMENT_TYPE = {
  EASY_PARCEL: { label: 'EasyParcel', transKey: 'order-csv-download-shipment-type-easy-parcel', value: 'easyParcel' },
  POS_LAJU: { label: 'Pos Laju', transKey: 'order-csv-download-shipment-type-pos-laju', value: 'posLaju' },
  BEST_EXPRESS: { label: 'BEST Express', transKey: 'order-csv-download-shipment-type-best-express', value: 'bestExpress' },
  NINJA_VAN: { label: 'NinjaVan', transKey: 'order-csv-download-shipment-type-ninja-van', value: 'ninjaVan' },
  GDEX: { label: 'GDEX', transKey: 'order-csv-download-shipment-type-gdex', value: 'gdex' },
  CITILINK: { label: 'CITILINK', transKey: 'order-csv-download-shipment-type-citilink', value: 'citilink' },
  JNT: { label: 'JNT', transKey: 'order-csv-download-shipment-type-jnt', value: 'jnt' },
  FMX: { label: 'FMX', transKey: 'order-csv-download-shipment-type-fmx', value: 'fmx' },
  PNP: { label: 'PNP', transKey: 'order-csv-download-shipment-type-pnp', value: 'pnp' },
  BX: { label: 'BX', transKey: 'order-csv-download-shipment-type-bx', value: 'bx' },
  SKYNET: { label: 'SKYNET', transKey: 'order-csv-download-shipment-type-skynet', value: 'skyNet' },
  BLUEBOX: { label: 'BlueBox', transKey: 'order-csv-download-shipment-type-bluebox', value: 'bluebox' },
  DHL: { label: 'DHL', transKey: 'order-csv-download-shipment-type-dhl', value: 'dhl' },
  LINE_CLEAR_EXPRESS: { label: 'Line Clear Express', transKey: 'order-csv-download-shipment-type-line-clear-express', value: 'lineClearExpress' }
};

// --------------------------- CSV Headers
export const POS_LAJU_CSV_HEADERS = [
  { label: 'No', key: 'no' },
  { label: 'Parcel Content', key: '' },
  { label: 'Content Description *', key: '' },
  { label: 'Value of goods (RM) *', key: 'subtotalPrice' },
  { label: 'Weight (Kg) *', key: 'totalWeight' },
  { label: 'Send Method *', key: '' },
  { label: 'Send Date *', key: 'sendDate' },
  { label: 'Sender Name *', key: 'userDetail.name' },
  { label: 'Sender Company', key: 'store.name' },
  { label: 'Sender Phone *', key: 'sellerPhone' },
  { label: 'Sender Email *', key: 'userDetail.email' },
  { label: 'Sender Address *', key: 'sellerAddress' },
  { label: 'Sender Postcode *', key: 'store.address.zipcode' },
  { label: 'Receiver Name *', key: 'shipment.recipientName' },
  { label: 'Receiver Company', key: '' },
  { label: 'Receiver Contact *', key: 'customerPhone' },
  { label: 'Receiver Email', key: '' },
  { label: 'Receiver Address *', key: 'customerAddress' },
  { label: 'Receiver Postcode *', key: 'shipment.address.zipcode' }
];

export const EASY_PARCEL_CSV_HEADERS = [
  { label: 'No*', key: 'no' },
  { label: 'Category', key: '' },
  { label: 'Parcel Content*', key: '' },
  { label: 'Parcel Value (RM)*', key: 'subtotalPrice' },
  { label: 'Weight (kg)*', key: 'totalWeight' },
  { label: 'Pick Up Date*', key: 'sendDate' },
  { label: 'Sender Name *', key: 'userDetail.name' },
  { label: 'Sender Company', key: 'store.name' },
  { label: 'Sender Contact*', key: 'sellerPhone' },
  { label: 'Sender Alt Contact', key: '' },
  { label: 'Sender Email', key: 'userDetail.email' },
  { label: 'Sender Address*', key: 'sellerAddress' },
  { label: 'Sender Postcode*', key: 'store.address.zipcode' },
  { label: 'Sender City*', key: 'store.address.city' },
  { label: 'Receiver Name*', key: 'shipment.recipientName' },
  { label: 'Receiver Company', key: '' },
  { label: 'Receiver Contact*', key: 'customerPhone' },
  { label: 'Receiver Alt Contact', key: '' },
  { label: 'Receiver Email', key: '' },
  { label: 'Receiver Address*', key: 'customerAddress' },
  { label: 'Receiver Postcode*', key: 'shipment.address.zipcode' },
  { label: 'Receiver City*', key: 'shipment.address.city' },
  { label: 'Courier Company', key: '' },
  { label: 'Alternative Courier Company', key: '' },
  { label: 'Tracking SMS (RM0.10)', key: '' },
  { label: 'Drop Off At Courier Branch', key: '' },
  { label: 'Reference', key: 'orderNumber' }
];

export const NINJA_VAN_CSV_HEADERS = [
  { label: 'REQUESTED TRACKING NUMBER', key: '' },
  { label: 'NAME', key: 'shipment.recipientName' },
  { label: 'ADDRESS 1', key: 'shipment.address.addressLine1' },
  { label: 'PACKAGE TYPE', key: 'packageType' },
  { label: 'ADDRESS 2', key: 'shipment.address.addressLine2' },
  { label: 'AREA', key: 'shipment.address.area' },
  { label: 'CITY', key: 'shipment.address.city' },
  { label: 'STATE', key: 'shipment.address.state' },
  { label: 'EMAIL', key: '' },
  { label: 'CONTACT', key: 'customerPhone' },
  { label: 'POSTCODE', key: 'shipment.address.zipcode' },
  { label: 'DELIVERY DATE', key: '' },
  { label: 'SIZE', key: '' },
  { label: 'WEIGHT', key: 'totalWeight' },
  { label: 'DELIVERY TYPE', key: '' },
  { label: 'SHIPPER ORDER NO', key: 'orderNumber' },
  { label: 'INSTRUCTIONS', key: '' },
  { label: 'WEEKEND DELIVERY', key: '' },
  { label: 'PARCEL DESCRIPTION', key: '' },
  { label: 'IS DANGEROUS GOOD', key: '' },
  { label: 'INSURED VALUE', key: '' },
  { label: 'VOLUME', key: '' },
  { label: 'LENGTH', key: '' },
  { label: 'WIDTH', key: '' },
  { label: 'HEIGHT', key: '' }
];

export const FMX_CSV_HEADERS = [
  { label: 'orderNumber', key: 'orderNumber' },
  { label: 'totalIncludeShipping', key: 'totalPrice' },
  { label: 'customerName', key: 'shipment.recipientName' },
  { label: 'shippingName', key: 'shipment.recipientName' },
  { label: 'orderItems', key: 'orderItems' },
  { label: 'shippingOption', key: '' },
  { label: 'shippingAddress1', key: 'shipment.address.addressLine1' },
  { label: 'shippingAddress2', key: 'shipment.address.addressLine2' },
  { label: 'shippingCity', key: 'shipment.address.city' },
  { label: 'shippingPhoneNumber', key: 'customerPhone' },
  { label: 'shippingPostcode', key: 'shipment.address.zipcode' },
  { label: 'shippingState', key: 'shipment.address.state' },
  { label: 'notes', key: '' },
  { label: 'weight', key: 'totalWeight' }
];

export const PNP_CSV_HEADERS = [
  { label: 'Consignee Name', key: 'shipment.recipientName' },
  { label: 'Consignee Number', key: 'customerPhone' },
  { label: 'Consignee Email', key: '' },
  { label: 'Consignee Postal Code', key: 'shipment.address.zipcode' },
  { label: 'Consignee Address', key: 'customerAddress' },
  { label: 'Building/Floor/Unit Number', key: '' },
  { label: 'Parcel Size', key: '' },
  { label: 'Parcel Details', key: '' }
];

export const BX_CSV_HEADERS = [
  { label: 'FB/IG NAME', key: 'customer.sourceUserName' },
  { label: 'shippingName', key: 'shipment.recipientName' },
  { label: 'orderItems', key: 'orderItems' },
  { label: 'shippingOption', key: 'shipment.method.label' },
  { label: 'shippingPhoneNumber', key: 'customerPhone' },
  { label: 'shippingAddress1', key: 'shipment.address.addressLine1' },
  { label: 'shippingAddress2', key: 'shipment.address.addressLine2' },
  { label: 'shippingCity', key: 'shipment.address.city' },
  { label: 'shippingCountry', key: 'shipment.address.country' },
  { label: 'shippingPostcode', key: 'shipment.address.zipcode' },
  { label: 'shippingState', key: 'shipment.address.state' },
  { label: 'totalExcludeShipping', key: 'subtotalPrice' },
  { label: 'shippingCost', key: 'shipment.shippingFee' },
  { label: 'totalIncludeShipping', key: 'totalPrice' }
];

export const SKYNET_CSV_HEADERS = [
  { label: 'RecipientName*', key: 'shipment.recipientName' },
  { label: 'CompanyName', key: 'store.name' },
  { label: 'Phone*', key: 'customerPhone' },
  { label: 'Address1*', key: 'shipment.address.addressLine1' },
  { label: 'Address2', key: 'shipment.address.addressLine2' },
  { label: 'PostCode*', key: 'shipment.address.zipcode' },
  { label: 'City*', key: 'shipment.address.city' },
  { label: 'State*', key: 'shipment.address.state' },
  { label: 'Country*', key: 'shipment.address.country' },
  { label: 'ShipperRef', key: 'orderNumber' },
  { label: 'Contents*', key: '' },
  { label: 'ShipmentType*', key: '' },
  { label: 'Pieces*', key: '' },
  { label: 'Weight (kg)*', key: 'totalWeight' },
  { label: 'Value (RM)*', key: 'subtotalPrice' },
  { label: 'Length(cm)*', key: '' },
  { label: 'Width(cm)*', key: '' },
  { label: 'Height(cm)*', key: '' }
];

// --------------------------- XLSX Headers
export const BEST_EXPRESS_XLSX_HEADERS = [
  '*Serial No',
  '*Full name',
  '*Phone Number',
  '*Postcode',
  '*Address(excluding continent, county and city)',
  'COD(RM)',
  'Coverage(RM)',
  '*Order weigth(kg)',
  '*order Type',
  '*order name',
  'waybill#',
  'Remark'
];

export const GDEX_XLSX_HEADERS = [
  'Company Name',
  'Receiver Name*',
  'Receiver Mobile*',
  'Receiver Alt Mobile',
  'Receiver Address 1*',
  'Receiver Address 2',
  'Receiver Address 3',
  'Receiver Postcode*',
  'Receiver City',
  'Receiver State',
  'Shipment Type*',
  'Shipment Content',
  'Shipment Value (RM)',
  'Total Pieces*',
  'Shipment Weight (kg)*',
  'Shipment Length (cm)',
  'Shipment Width (cm)',
  'Shipment Height (cm)',
  'Dangerous Goods*',
  'Customer Note 1',
  'Customer Note 2',
  'Order ID',
  'Enhanced Liability'
];

export const CITILINK_XLSX_HEADERS = [
  'Service Type',
  'Quantity',
  'Weight',
  'Remark',
  'COD Currency',
  'Consignee Name',
  'Consignee Company Name',
  'Consignee Email',
  'Consignee Contact Number',
  'Consignee Fax',
  'Consignee Contact Person',
  'Consignee Department',
  'Consignee Add 1',
  'Consignee Add 2',
  'Consignee Add 3',
  'Consignee Postcode',
  'Consignee City',
  'Consignee State',
  'Consignee Country',
  'ShipmentRef No',
  'Description',
  'Package Type'
];

export const JNT_XLSX_HEADERS = [
  'Actual Weight',
  'Sender Name',
  'Sender Phone',
  'Sender Postcode',
  'Sender Address',
  'Recipient Name',
  'Recipient Phone',
  'Recipient Postcode',
  'Recipient Address Type',
  'Recipient address',
  'Payment Method',
  'SERVICE TYPE',
  'Number of packages',
  'Parcel Type',
  'Goods Name',
  'Item length(cm)',
  'Item width(cm)',
  'Item height(cm)',
  'Amount',
  'Goods Type',
  'Customer Order Number',
  'Remarks',
  'Goods Value',
  'Insurance',
  'COD'
];

export const BLUEBOX_XLSX_HEADERS = [
  'Username',
  'Delivery Date',
  'Bid No',
  'Receiver Name',
  'Receiver Contact No.',
  'Company Name',
  'Unit NO.',
  'Receiver Postcode',
  'Receiver Address',
  'Remark',
  'XS',
  'S',
  'M',
  'L',
  'XL'
];

export const DHL_XLSX_HEADERS = [
  'Pick-up Account Number',
  'Shipment Order ID',
  'Shipping Service Code',
  'Company',
  'Consignee Name',
  'Address Line 1',
  'Address Line 2',
  'Address Line 3',
  'City',
  'State',
  'Postal Code',
  'Destination Country Code',
  'Phone Number',
  'Email Address',
  'Shipment Weight (g)',
  'Currency Code',
  'Total Declared Value',
  'Is Insured',
  'Insurance',
  'Is COD',
  'Cash on Delivery Value',
  'Shipment Description',
  'Remarks',
  'Service1',
  'Service2',
  'Handover Method',
  'IsMult',
  'Delivery Option',
  'No of Pieces',
  'PieceID',
  'Piece Description',
  'Piece Weight',
  'Piece COD',
  'Piece Insurance',
  'Piece Billing Reference 1',
  'Piece Billing Reference 2'
];

export const LINE_CLEAR_EXPRESS_XLSX_HEADERS = [
  'Types_Of_Services',
  'Service_Type',
  'Packaging_Id',
  'Delivery_Name',
  'Delivery_Building_Unit',
  'Delivery_Address',
  'Delivery_Address_2',
  'Delivery_Postal_Code',
  'Delivery_Phone_Number',
  'Delivery Company Name',
  'Shipment_Type',
  'Mode',
  'Reference_Number',
  'Content_Of_Shipment',
  'Quantity',
  'Weight',
  'Length',
  'Width',
  'Height',
  'DO_Numbers',
  'ProtectionPlan_Product_Description',
  'ProtectionPlan_Quantity',
  'Total_Value',
  'Parcel_Value',
  'Sales_Tax',
  'Delivery_Charges',
  'COD',
  'SST',
  'Others'
];

// =========================== Order's Payment CSV
// --------------------------- Payment Field Type
export const PAYMENT_FIELDS_TYPES = {
  PAYMENT_ONLY: { label: 'Payment Only', transKey: 'order-csv-download-payment-fields-type-payment-only', value: 'paymentOnly' },
  PAYMENT_ORDERS: { label: 'Payment with Orders', transKey: 'order-csv-download-payment-fields-type-payment-orders', value: 'paymentOrders' },
  PAYMENT_PRODUCT_SOLD: {
    label: 'Payment with Product Sold',
    transKey: 'order-csv-download-payment-fields-type-payment-productSold',
    value: 'paymentProductSold'
  }
};

// --------------------------- CSV Headers
export const PAYMENT_ONLY_CSV_HEADERS = [
  { label: 'Order Number', key: 'orderNumber' },
  { label: 'Customer', key: 'customer' },
  { label: 'Order Status', key: 'orderStatus' },
  { label: 'Status', key: 'status' },
  { label: 'Method', key: 'method' },
  { label: 'PG Payment Method', key: 'pgPaymentMethod' },
  { label: 'Amount', key: 'amount' },
  { label: 'Settlement Amount', key: 'settlementAmount' },
  { label: 'Ref ID', key: 'refId' },
  { label: 'Paid At', key: 'paidAt' },
  { label: 'Receipt File URLs', key: 'receiptFileUrls' },
  { label: 'Products', key: 'productsSummary' },
  { label: 'Order Remarks', key: 'orderRemarks' },
  { label: 'Shipping Method', key: 'shipmentMethodLabel' },
  { label: 'Order Shipping Remarks', key: 'orderShippingRemarks' }
];

export const PAYMENT_ORDERS_CSV_HEADERS = [
  { label: 'Order Number', key: 'orderNumber' },
  { label: 'Customer', key: 'customer' },
  { label: 'Order Status', key: 'orderStatus' },
  { label: 'Status', key: 'status' },
  { label: 'Live Streamers', key: 'product.liveStreamers' },
  { label: 'Live Streamer Remarks', key: 'product.liveStreamerRemarks' },
  { label: 'Method', key: 'method' },
  { label: 'PG Payment Method', key: 'pgPaymentMethod' },
  { label: 'Settlement Amount', key: 'settlementAmount' },
  { label: 'Total Payment Amount', key: 'amount' },
  { label: 'Subtotal Payment Amount', key: 'subtotalPrice' },
  { label: 'Shipping Fee', key: 'shippingFee' },
  { label: 'Extra Fee', key: 'extraFee' },
  { label: 'Discount', key: 'discount' },
  { label: 'Ref ID', key: 'refId' },
  { label: 'Paid At', key: 'paidAt' },
  { label: 'Receipt File URLs', key: 'receiptFileUrls' },
  { label: 'Product Keyword', key: 'product.keyword' },
  { label: 'Product SKU', key: 'product.sku' },
  { label: 'Product Name', key: 'product.name' },
  { label: 'Product Purchase Date', key: 'product.purchasedDate' },
  { label: 'Product Qty', key: 'product.quantity' },
  { label: 'Product Purchase Source', key: 'product.purchasedSources' },
  { label: 'Post URL', key: 'product.purchasedPostUrls' },
  { label: 'Order Remarks', key: 'orderRemarks' },
  { label: 'Shipping Method', key: 'shipmentMethodLabel' },
  { label: 'Order Shipping Remarks', key: 'orderShippingRemarks' }
];

export const PAYMENT_PRODUCT_SOLD_CSV_HEADERS = [
  { label: 'Product Name', key: 'name' },
  { label: 'Product Keyword', key: 'keyword' },
  { label: 'Product SKU', key: 'sku' },
  { label: 'Quantity Sold', key: 'quantity' },
  { label: 'Total Paid Amount', key: 'amount' }
];

// =========================== Reporting CSV Headers
export const PRODUCT_SOLD_CSV_HEADERS = [
  { label: 'Order Number', key: 'orderNumber' },
  { label: 'Customer Name', key: 'buyerName' },
  { label: 'Product Keyword', key: 'matchingKeyword' },
  { label: 'Product Label', key: 'label' },
  { label: 'Quantity', key: 'quantitySold' },
  { label: 'Price', key: 'totalPrice' },
  { label: 'Order Status', key: 'status' },
  { label: 'Purchased Date', key: 'purchasedDate' }
];

// =========================== Customer Details
export const customerDetailsTabsObj = {
  GENERAL: {
    code: 'general',
    label: 'General',
    isDefault: true
  },
  ORDERS: {
    code: 'orders',
    label: 'Orders'
  }
};

/* ----------------------------Constants functions--------------------------- */
/* -------------Function that related and only use by constant--------------- */
export const constructConstantLabel = (constants, text, fallbackValue) => {
  const foundConst = constants.find(type => type.value === text);
  return guard(() => foundConst.label, fallbackValue);
};

export const getConstantLabelFromCode = (constant, codeToFind, codeKey = 'code', labelKey = 'label') => {
  if (!codeToFind) {
    return '-';
  }
  const foundConstant = Object.values(constant).find(constant => constant[codeKey] === codeToFind);

  if (!foundConstant) {
    return codeToFind;
  }
  return foundConstant[labelKey];
};

export const getFilteredConstants = (constants, filterKey, filterValue, returnKey = 'code') => {
  if (!constants) {
    return [];
  }
  const filteredConstants = Object.values(constants).filter(constant => constant[filterKey] === filterValue);
  if (returnKey) {
    return filteredConstants.map(constant => constant[returnKey]);
  }
  return filteredConstants;
};

// URL
export const getOrderExtCheckoutLink = (orderNumber, accessCode) =>
  `http${IS_DEV ? '' : 's'}://${window.location.host}/checkout/${orderNumber}?accessCode=${accessCode}`;
