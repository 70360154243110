import React, { useMemo } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';

import FormItem from 'components/FormItem/FormItem';

import { checkIsEmail } from 'utils/general';

const { Option } = Select;

const generateRules = (oriRules, isEmail) => {
  let newRules = [...oriRules];
  if (isEmail) {
    newRules.push({
      validator: async (rule, values) => {
        if (!values) {
          return;
        }

        for (let i = 0; i < values.length; i++) {
          const value = values[i];
          if (!checkIsEmail(value)) {
            throw new Error(`One of your email(s) is invalid: ${value}`);
          }
        }
      }
    });
  }
  return newRules;
};

const FormMultiInputTag = ({
  disabled,
  label,
  name,
  placeholder,
  requiredErrorMessage,
  selections,
  onChange,
  onSearch,
  filterOption,
  defaultValue,
  isEmail,
  isShowDropdown,
  isSearching,
  extraProps,
  extraRules,
  ...props
}) => {
  const rules = useMemo(() => generateRules(extraRules, isEmail), [extraRules, isEmail]);
  const searchModeProps =
    onSearch || filterOption
      ? {
          showSearch: true,
          loading: isSearching,
          onSearch,
          filterOption
        }
      : {};

  return (
    <>
      <FormItem requiredErrorMessage={requiredErrorMessage} name={name} label={label} initialValue={defaultValue} extraRules={rules} {...props}>
        <Select
          disabled={disabled}
          mode="tags"
          placeholder={placeholder}
          {...extraProps}
          {...searchModeProps}
          onChange={onChange}
          dropdownStyle={!isShowDropdown && { display: 'none' }}
          {...props}
        >
          {selections.map(selection => (
            <Option key={`${selection.value}-${selection.label}`} value={selection.value}>
              {selection.label}
            </Option>
          ))}
        </Select>
      </FormItem>
    </>
  );
};

FormMultiInputTag.propTypes = {
  isSearchable: PropTypes.bool,
  selections: PropTypes.array,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  filterOption: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isSearching: PropTypes.bool,
  isShowDropdown: PropTypes.bool
};

FormMultiInputTag.defaultProps = {
  isSearchable: true,
  selections: [],
  extraRules: [],
  onChange: () => {},
  onSearch: () => {},
  filterOption: (input, option) => {
    if (option.props.children) {
      return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
    return option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  },
  isEmail: false,
  isSearching: false,
  isShowDropdown: false
};

export default FormMultiInputTag;
