import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  FieldTimeOutlined,
  FormOutlined,
  InfoCircleFilled,
  SplitCellsOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Button, Card, Col, Form, List, message, Modal, Radio, Row, Skeleton, Table, Tag, Tooltip } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Files from 'components/Files/Files';
import FilesV2 from 'components/Files/FilesV2';
import FormInput from 'components/FormInput/FormInput';
import FormInputNumber from 'components/FormInputNumber/FormInputNumber';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';
import FormSelection from 'components/FormSelection/FormSelection';
import FormUpload from 'components/Upload/FormUpload/FormUpload';
import PhotoManager from 'components/PhotoManager/PhotoManager';
import FormGroupSelectionInput from 'components/FormGroupSelectionInput/FormGroupSelectionInput';
import OrderTagSelection from 'components/OrderTagSelection/OrderTagSelection';
import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import PriceDisplay from 'components/PriceDisplay/PriceDisplay';
import TextButton from 'components/TextButton/TextButton';
import PromotionInfoItem from 'components/PromotionInfoItem/PromotionInfoItem';
import QuickAddProductModal from 'components/QuickAddProductModal/QuickAddProductModal';
import CustomerRemarksModal, { useCustomerRemarksModal } from 'components/CustomerRemarksModal/CustomerRemarksModal';

import { useRefetchProducts } from 'apis/product';

import { constructConstantLabel } from 'utils/constants';
import { checkIsArrayEmpty, checkIsObjectEmpty, guard } from 'utils/general';
import { constructColumn } from 'utils/table/table';
import { withAppContext } from 'contexts/AppContext/AppContext';

import PaymentInfoModal from '../PaymentInfoModal/PaymentInfoModal';
import { IsBuyerRecipientCheckbox, IsUpdateBuyerProfileCheckbox, PaymentRefIdLinkTypeText } from './OrderForm.styles';

const { useForm } = Form;
const { confirm } = Modal;
const { Group: RadioGroup } = Radio;

const BUYER_OPTION_NEW = 'newBuyer';
const BUYER_OPTION_EXISTING = 'existingBuyer';

const formatAddedOrderProducts = (orderProducts = []) =>
  orderProducts.map(orderProduct => ({
    _id: orderProduct.varianceId || orderProduct.productId,
    orderKeyword: orderProduct.matchingKeyword,
    orderProductId: orderProduct._id,
    price: orderProduct.priceDetails.price,
    label: orderProduct.label,
    value: orderProduct.varianceId || orderProduct.productId,
    inventory: orderProduct.maxInventory,
    quantity: orderProduct.quantity,
    isDeleted: orderProduct.isDeleted
  }));

const getOrderProductsQtyFields = formattedOrderProducts => {
  const qtyFormFields = {};
  formattedOrderProducts.forEach(
    formattedOrderProduct => (qtyFormFields[`${formattedOrderProduct.value}-quantity`] = formattedOrderProduct.quantity)
  );

  return qtyFormFields;
};

const usePaymentInfo = ({ t, paymentMethods = [], paymentStatuses = [], paymentStatusesConst = {} }) => {
  const [selectedPaymentId, setSelectedPaymentId] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const statusColors = !checkIsObjectEmpty(paymentStatusesConst) && {
    [paymentStatusesConst.PENDING.code]: '#FFBA00',
    [paymentStatusesConst.SUCCESS.code]: '#52C41A',
    [paymentStatusesConst.FAILED.code]: '#F5222D'
  };

  const _handleOnRefIdClick = paymentId => {
    setSelectedPaymentId(paymentId);
    setIsModalVisible(true);
  };

  const constructColumns = () => {
    return [
      {
        ...constructColumn(t('pageOrderDetails:payment-table-header-ref'), 'refId'),
        render: (refId, record) => (
          <PaymentRefIdLinkTypeText onClick={() => _handleOnRefIdClick(record._id)}>
            {!!refId ? refId : constructConstantLabel(paymentMethods, record.method)}
          </PaymentRefIdLinkTypeText>
        )
      },
      {
        ...constructColumn(t('pageOrderDetails:payment-table-header-status'), 'status'),
        render: (status, record) => (
          <div>
            <span style={{ color: statusColors[status] }}>{constructConstantLabel(paymentStatuses, status)}</span>
            <br />
            {record.paidAt && (
              <span style={{ color: statusColors[status] }}>
                <b>
                  <FieldTimeOutlined /> {moment(record.paidAt).format('YYYY-MM-DD h:mma')}
                </b>
              </span>
            )}
          </div>
        )
      },
      {
        ...constructColumn(t('pageOrderDetails:payment-table-header-amount'), 'amount', { width: '15%' }),
        render: amount => <PriceDisplay amount={amount} isHideSymbol />
      },
      {
        ...constructColumn(t('pageOrderDetails:payment-table-header-method'), 'method'),
        render: method => constructConstantLabel(paymentMethods, method)
      },
      {
        ...constructColumn(t('pageOrderDetails:payment-table-header-receipts'), 'receipts'),
        render: (receipts, record) => {
          if (!checkIsArrayEmpty(receipts)) {
            return <FilesV2 files={receipts} showIcon />;
          } else if (!checkIsArrayEmpty(record.receiptFiles)) {
            return <Files files={record.receiptFiles} showIcon />;
          }

          return '-';
        }
      }
    ];
  };

  const handleOnAddNewPaymentClick = () => {
    setSelectedPaymentId('');
    setIsModalVisible(true);
  };

  const handleOnCloseModal = () => {
    setSelectedPaymentId('');
    setIsModalVisible(false);
  };

  return {
    selectedPaymentId,
    showPaymentModal: isModalVisible,
    constructPaymentTableColumns: constructColumns,
    onAddNewPaymentClick: handleOnAddNewPaymentClick,
    onClosePaymentModal: handleOnCloseModal
  };
};

const useQuickAddProductModal = () => {
  const refetchProducts = useRefetchProducts();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newlyCreatedProductId, setNewlyCreatedProductId] = useState(null);

  const handleOnOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleOnCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOnCreated = newProduct => {
    refetchProducts();
    setNewlyCreatedProductId(newProduct._id);
    handleOnCloseModal();
  };

  return {
    showQuickAddProductModal: isModalVisible,
    newlyCreatedProductId,
    setNewlyCreatedProductId,
    onOpenQuickAddProductModal: handleOnOpenModal,
    onCloseQuickAddProductModal: handleOnCloseModal,
    handleOnProductCreated: handleOnCreated
  };
};

const useBuyerInfo = ({ form, buyersSelection, socialMediaUsersSelection, countries, countryCodes, isNew, orderFormInitialValue }) => {
  const [selectedBuyer, setSelectedBuyer] = useState({});
  const [buyerOption, setBuyerOption] = useState(BUYER_OPTION_EXISTING);
  const [isBuyerTheRecipient, setIsBuyerTheRecipient] = useState(true);
  const [isUpdateBuyerProfileChecked, setIsUpdateBuyerProfileChecked] = useState(true);

  const formatRecipientInfoForShipmentFields = useCallback(
    details => {
      return {
        recipientName: details.name || details.recipientName || '',
        recipientContact: details.contact ||
          details.recipientContact || {
            countryCode: countryCodes && countryCodes[0] && countryCodes[0].value,
            contactNumber: ''
          },
        recipientEmail: details.email || details.recipientEmail || '',
        address: {
          addressLine1: (details.address && details.address.addressLine1) || '',
          addressLine2: (details.address && details.address.addressLine2) || '',
          city: (details.address && details.address.city) || '',
          zipcode: (details.address && details.address.zipcode) || '',
          state: (details.address && details.address.state) || '',
          country: (details.address && details.address.country) || (countries && countries[0] && countries[0].value)
        }
      };
    },
    [countryCodes, countries]
  );

  const handleOnBuyerOptionsChange = e => {
    const selectedBuyerOption = e.target.value;
    e.preventDefault();
    setBuyerOption(selectedBuyerOption);
    setSelectedBuyer({});
    setIsBuyerTheRecipient(false);
    form.setFieldsValue({
      customer:
        selectedBuyerOption === BUYER_OPTION_NEW
          ? {
              _id: undefined,
              socialMediaUserId: '',
              name: '',
              contact: {
                countryCode: countryCodes && countryCodes[0] && countryCodes[0].value,
                contactNumber: ''
              },
              email: ''
            }
          : orderFormInitialValue.customer,
      shipment: formatRecipientInfoForShipmentFields({})
    });
  };

  const handleOnBuyerSuggestionSelected = selectedBuyerId => {
    const selectedBuyer = buyersSelection.find(buyerSelection => buyerSelection.value === selectedBuyerId);
    setSelectedBuyer(selectedBuyer);
  };

  const handleOnSocialMediaUserSuggestionSelect = selectedSocialMediaUserId => {
    const selectedSocialMediaUser = socialMediaUsersSelection.find(buyerSelection => buyerSelection.value === selectedSocialMediaUserId);
    form.setFieldsValue({
      customer: {
        name: selectedSocialMediaUser?.name,
        contact: {
          countryCode: countryCodes && countryCodes[0] && countryCodes[0].value,
          contactNumber: ''
        },
        email: ''
      }
    });
  };

  const handleOnIsBuyerTheRecipientCheck = () => {
    const newStateOfIsBuyerTheRecipient = !isBuyerTheRecipient;
    setIsBuyerTheRecipient(newStateOfIsBuyerTheRecipient);
    if (isNew) {
      const currentFormValues = form.getFieldsValue();
      const currentBuyerDetails = Object.keys(selectedBuyer).length > 0 ? selectedBuyer : currentFormValues.customer;
      form.setFieldsValue({
        shipment: newStateOfIsBuyerTheRecipient ? formatRecipientInfoForShipmentFields(currentBuyerDetails) : orderFormInitialValue.shipment
      });
    }
  };

  const handleOnIsUpdateBuyerProfileCheck = () => {
    const newStateOfIsUpdateBuyerProfileChecked = !isUpdateBuyerProfileChecked;
    setIsUpdateBuyerProfileChecked(newStateOfIsUpdateBuyerProfileChecked);
  };

  return {
    buyerOption,
    isUpdateBuyerProfileChecked,
    isBuyerTheRecipient,
    selectedBuyer,
    onInitBuyerSelect: setSelectedBuyer,
    formatRecipientInfoForShipmentFields,
    onBuyerOptionsChange: handleOnBuyerOptionsChange,
    onBuyerSuggestionSelect: handleOnBuyerSuggestionSelected,
    onSocialMediaUserSuggestionSelect: handleOnSocialMediaUserSuggestionSelect,
    onIsBuyerTheRecipientCheck: handleOnIsBuyerTheRecipientCheck,
    onIsUpdateBuyerProfileCheck: handleOnIsUpdateBuyerProfileCheck
  };
};

const useOrderProducts = ({ t, form, productSuggestions, onSearchProduct }) => {
  const [addedOrderProducts, setAddedOrderProducts] = useState([]);

  const productSubtotalPrice = useMemo(() => addedOrderProducts.reduce((totalAmount, product) => totalAmount + product.price * product.quantity, 0), [
    addedOrderProducts
  ]);

  const handleOnProductSelected = suggestionValue => {
    const selectedSuggestedProduct = productSuggestions.find(product => product.value === suggestionValue);

    if (selectedSuggestedProduct) {
      const foundProductInAddedProducts = addedOrderProducts.find(addedProduct => addedProduct._id === selectedSuggestedProduct._id);
      if (foundProductInAddedProducts && foundProductInAddedProducts.label) {
        message.error(t('pageOrderDetails:form-error-message-add-same-product'));

        form.setFieldsValue({
          orderProduct: ''
        });
      } else {
        setAddedOrderProducts(currentProducts => {
          return [...currentProducts, { ...selectedSuggestedProduct, quantity: 1 }];
        });

        form.setFieldsValue({
          orderProduct: '',
          [`${selectedSuggestedProduct.value}-quantity`]: 1
        });
      }
    }

    onSearchProduct('');
  };

  const handleOnProductQtyChange = (qty, selectedProductId) => {
    setAddedOrderProducts(currentProducts => {
      const currentIdx = currentProducts.findIndex(currentProduct => currentProduct._id === selectedProductId);
      currentProducts[currentIdx] = {
        ...currentProducts[currentIdx],
        quantity: qty
      };

      return [...currentProducts];
    });
  };

  const handleOnProductDeleteClick = selectedProductId => {
    setAddedOrderProducts(currentProducts => {
      const newProductsList = currentProducts.filter(addedProduct => addedProduct._id !== selectedProductId);
      const selectedProduct = currentProducts.find(addedProduct => addedProduct._id === selectedProductId);

      form.setFieldsValue({
        [`${selectedProduct.value}-quantity`]: 0
      });

      return newProductsList;
    });
  };

  return {
    addedOrderProducts,
    productSubtotalPrice,
    onInitOrderProducts: setAddedOrderProducts,
    onProductSelect: handleOnProductSelected,
    onProductQtyChange: handleOnProductQtyChange,
    onProductDelete: handleOnProductDeleteClick
  };
};

const useOrderForm = ({ t, form, data, countryCodes, countries, onSave }) => {
  const orderFormInitialValue = useMemo(() => {
    if (!data) {
      return undefined;
    }

    const existingCustomerData = data && data.customer;
    const existingCustomerContactData = data && data.customer && data.customer.contact;
    const existingShipmentData = data && data.shipment;
    const existingShipmentRecipientContactData = data && data.shipment && data.shipment.recipientContact;
    const existingShipmentRecipientAddressData = data && data.shipment && data.shipment.address;
    return {
      ...data,
      customer: {
        ...existingCustomerData,
        contact: existingCustomerContactData || {
          countryCode: countryCodes && countryCodes[0] && countryCodes[0].value
        }
      },
      shipment: {
        ...existingShipmentData,
        recipientContact: existingShipmentRecipientContactData || {
          countryCode: countryCodes && countryCodes[0] && countryCodes[0].value
        },
        address: existingShipmentRecipientAddressData || {
          country: countries && countries[0] && countries[0].value
        }
      },
      tags: data.tags?.map(tag => tag.tagId)
    };
  }, [countries, countryCodes, data]);

  const _formatOrderProductsPayload = orderProducts => {
    return orderProducts.map(orderProduct => ({
      ...(orderProduct.orderProductId && { orderProductId: orderProduct.orderProductId }),
      keyword: orderProduct.orderKeyword,
      quantity: orderProduct.quantity,
      isDeleted: orderProduct.isDeleted
    }));
  };

  const handleOnSaveClick = async ({
    isUpdateBuyerProfileChecked,
    addedOrderProducts,
    promotions,
    updateDeliveryProof,
    setIsGeneratingPresaveSummary
  }) => {
    setIsGeneratingPresaveSummary(true);
    try {
      const allFieldsValue = form.getFieldsValue();
      const deliveryProofV2 = await updateDeliveryProof();
      if (addedOrderProducts.length > 0) {
        const formattedOrderProductsPayload = _formatOrderProductsPayload(addedOrderProducts);
        const hasShipmentDetails = allFieldsValue.shipment && Object.keys(allFieldsValue.shipment).length > 0;
        const hasRecipientName = guard(() => hasShipmentDetails && !!allFieldsValue.shipment.recipientName, false);
        const hasRecipientEmail = guard(() => !!allFieldsValue.shipment.recipientEmail, false);
        const hasCompleteRecipientContact = guard(() => hasShipmentDetails && !!allFieldsValue.shipment.recipientContact.contactNumber, false);
        const hasCompleteBuyerRecipientContact = guard(() => !!allFieldsValue.customer.contact.contactNumber, false);

        const newOrderPayload = {
          ...allFieldsValue,
          orderNumber: orderFormInitialValue.orderNumber || '',
          customer: {
            ...allFieldsValue.customer,
            ...(!hasCompleteBuyerRecipientContact && { contact: undefined }),
            ...(isUpdateBuyerProfileChecked &&
              hasShipmentDetails && {
                address: allFieldsValue.shipment.address,
                ...(hasCompleteRecipientContact && { contact: hasCompleteRecipientContact && allFieldsValue.shipment.recipientContact }),
                ...(hasRecipientName && { name: allFieldsValue.shipment.recipientName }),
                ...(hasRecipientEmail && { email: allFieldsValue.shipment.recipientEmail })
              })
          },
          ...((deliveryProofV2 || hasShipmentDetails) && {
            shipment: {
              ...allFieldsValue.shipment,
              ...(!hasCompleteRecipientContact && { recipientContact: null }),
              deliveryProofV2
            }
          }),
          products: formattedOrderProductsPayload,
          promotionDetails: { promotions }
        };

        onSave(newOrderPayload);
      } else {
        setIsGeneratingPresaveSummary(false);
        message.error(t('pageOrderDetails:form-error-message-save-no-product-added'));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsGeneratingPresaveSummary(false);
      message.error(t('common:update-fail-message'));
    }
  };

  const handleOnSaveFailed = ({ errorFields }) => {
    errorFields.forEach(field => message.error(field.errors[0]));
  };

  return { orderFormInitialValue, onFormSave: handleOnSaveClick, onFormSaveFail: handleOnSaveFailed };
};

// ========================== Promotion Info Component & Hooks
const PromotionInfoCard = ({
  t,
  isPromotionSuggestionsLoading,
  promotionSuggestions,
  promotions,
  storeCurrency,
  invalidVoucherIds,
  onRemoveClick,
  onPromoSelect
}) => {
  return (
    <Col span={24}>
      <Card title={t('pageOrderDetails:card-title-promotion-info')}>
        <FormSelection
          name="orderPromotion"
          placeholder={t('pageOrderDetails:promotion-text-apply-promotional-code')}
          selections={promotionSuggestions}
          isSearching={isPromotionSuggestionsLoading}
          onChange={onPromoSelect}
        />
        <hr style={{ borderTop: '0px' }} />
        <br />
        {promotions.map((promotion, index) => {
          return (
            <PromotionInfoItem
              key={index}
              promotion={promotion}
              storeCurrency={storeCurrency}
              onRemoveClick={onRemoveClick}
              extraStyle={{ ...(index !== promotions.length - 1 && { marginBottom: 16 }) }}
              isInvalidVoucher={invalidVoucherIds.includes(promotion._id) || invalidVoucherIds.includes(promotion.customerPromo)}
            />
          );
        })}
      </Card>
    </Col>
  );
};

const usePromotionInfo = ({ t, form, promotionDetails, promotionSuggestions }) => {
  const [promotions, setPromotions] = useState(promotionDetails ? promotionDetails.promotions : []);

  const handleOnVoucherRemove = useCallback(
    promotionId => {
      confirm({
        title: <span>{t('pageOrderDetails:promotion-confirm-modal-remove-title')}</span>,
        content: (
          <>
            <p>{t('pageOrderDetails:promotion-confirm-modal-remove-content-1')}</p>
            {t('pageOrderDetails:promotion-confirm-modal-remove-content-2')}
          </>
        ),
        onOk: () => {
          setPromotions(promotions.filter(promo => promo.promotionId !== promotionId && promo._id !== promotionId));
        },
        okText: t('pageOrderDetails:promotion-confirm-modal-remove-button-ok-text')
      });
    },
    [t, promotions]
  );

  const handleOnPromoCodeAdd = suggestionValue => {
    const selectedSuggestedPromotion = promotionSuggestions.find(promotion => promotion.value === suggestionValue);

    if (selectedSuggestedPromotion) {
      const foundProductInAddedPromotions = promotions.find(
        promotion => promotion._id === selectedSuggestedPromotion._id || promotion.promotionId === selectedSuggestedPromotion._id
      );
      if (foundProductInAddedPromotions && foundProductInAddedPromotions.code) {
        message.error(t('pageOrderDetails:promotion-error-message-add-same-promotion'));
      } else if (!selectedSuggestedPromotion.isStackable && promotions.length > 0) {
        message.error(t('pageOrderDetails:promotion-error-message-promotion-unstackable'));
      } else {
        const hasUnstackablePromo = promotions.some(promotion => !promotion.isStackable);
        if (hasUnstackablePromo) {
          message.error(t('pageOrderDetails:promotion-error-message-order-exists-unstackable'));
        } else {
          setPromotions([...promotions, selectedSuggestedPromotion]);
        }
      }
    }

    form.setFieldsValue({ orderPromotion: '' });
  };

  return { promotions, handleOnVoucherRemove, handleOnPromoCodeAdd };
};

const BuyerRadioButtons = ({ t, onChange, value, disabled }) => (
  <div style={{ marginBottom: '24px' }}>
    <RadioGroup onChange={onChange} value={value} disabled={disabled} size="large">
      <Radio style={{ fontSize: '16px' }} value={BUYER_OPTION_NEW}>
        {t('pageOrderDetails:form-button-radio-new-buyer')}
      </Radio>
      <Radio style={{ fontSize: '16px' }} value={BUYER_OPTION_EXISTING}>
        {t('pageOrderDetails:form-button-radio-existing-buyer')}
      </Radio>
    </RadioGroup>
  </div>
);

const OrderForm = ({
  data,
  isButtonSaveLoading,
  isNew,
  isLoading,
  isProductSuggestionsLoading,
  isPromotionSuggestionsLoading,
  orderId,
  setIsGeneratingPresaveSummary,
  onCancel,
  onSave,
  onSplitOrderBtnClick,
  onSearchBuyer,
  onSearchSocialMediaUser,
  onSearchProduct,
  orderPayments,
  orderStatuses,
  orderStatusesConst,
  paymentMethods,
  paymentStatuses,
  paymentStatusesConst,
  shipmentMethods,
  countryCodes,
  countries,
  buyersSelection,
  socialMediaUsersSelection,
  productSuggestions = [],
  promotionSuggestions = [],
  invalidVoucherIds,
  storeCurrency,
  store
}) => {
  const { t } = useTranslation(['common', 'pageOrder', 'pageOrderDetails']);
  const [form] = useForm();
  const deliveryProofRef = useRef(null);
  const [formattedPromotionSuggestions, setFormattedPromotionSuggestions] = useState([]);
  const [currentDeliveryProof, setCurrentDeliveryProof] = useState([]);
  const shouldUsePhotoManager = data ? !data.deliveryProof : true;

  const { orderFormInitialValue, onFormSave, onFormSaveFail } = useOrderForm({ t, form, data, countryCodes, countries, onSave });
  const { addedOrderProducts, productSubtotalPrice, onInitOrderProducts, onProductSelect, onProductQtyChange, onProductDelete } = useOrderProducts({
    t,
    form,
    productSuggestions,
    onSearchProduct
  });
  const {
    buyerOption,
    isUpdateBuyerProfileChecked,
    isBuyerTheRecipient,
    selectedBuyer,
    onInitBuyerSelect,
    onBuyerOptionsChange,
    onBuyerSuggestionSelect,
    onSocialMediaUserSuggestionSelect,
    onIsBuyerTheRecipientCheck,
    onIsUpdateBuyerProfileCheck,
    formatRecipientInfoForShipmentFields
  } = useBuyerInfo({
    form,
    buyersSelection,
    socialMediaUsersSelection,
    countries,
    countryCodes,
    isNew,
    orderFormInitialValue
  });
  const { selectedPaymentId, showPaymentModal, constructPaymentTableColumns, onAddNewPaymentClick, onClosePaymentModal } = usePaymentInfo({
    t,
    paymentMethods,
    paymentStatuses,
    paymentStatusesConst
  });
  const { promotions, handleOnVoucherRemove, handleOnPromoCodeAdd } = usePromotionInfo({
    t,
    form,
    promotionDetails: data.promotionDetails,
    promotionSuggestions
  });
  const {
    isCustomerRemarksModalVisible,
    setIsCustomerRemarksModalVisible,
    selectedCustomerId,
    handleOnCustomerRemarksBtnClick
  } = useCustomerRemarksModal();
  const {
    showQuickAddProductModal,
    newlyCreatedProductId,
    setNewlyCreatedProductId,
    onOpenQuickAddProductModal,
    onCloseQuickAddProductModal,
    handleOnProductCreated
  } = useQuickAddProductModal();

  useEffect(() => {
    if (!currentDeliveryProof.length && data?.shipment?.deliveryProofV2) {
      setCurrentDeliveryProof([data.shipment.deliveryProofV2]);
    }
  }, [currentDeliveryProof, data]);

  useEffect(() => {
    if (!isNew) {
      const formattedOrderProducts = formatAddedOrderProducts(orderFormInitialValue.products);
      onInitBuyerSelect(orderFormInitialValue.customer);
      onInitOrderProducts(formattedOrderProducts);

      form.setFieldsValue({
        ...getOrderProductsQtyFields(formattedOrderProducts)
      });
    }
  }, [orderFormInitialValue, form, isNew, orderStatusesConst, paymentMethods, onInitBuyerSelect, onInitOrderProducts]);

  useEffect(() => {
    const currentFormValues = form.getFieldsValue();
    if (isNew) {
      const currentBuyerDetails = Object.keys(selectedBuyer).length > 0 ? selectedBuyer : currentFormValues.customer;
      form.setFieldsValue({
        status: orderStatusesConst.PENDING.code,
        shipment: isBuyerTheRecipient ? formatRecipientInfoForShipmentFields(currentBuyerDetails) : orderFormInitialValue.shipment
      });
    }
  }, [
    orderFormInitialValue,
    form,
    isNew,
    orderStatusesConst,
    paymentMethods,
    isBuyerTheRecipient,
    selectedBuyer,
    formatRecipientInfoForShipmentFields
  ]);

  useEffect(() => {
    setFormattedPromotionSuggestions(
      promotionSuggestions.map(promotionSuggestion => {
        return {
          ...promotionSuggestion,
          ...(promotionSuggestion.minSpendingAmount && productSubtotalPrice < promotionSuggestion.minSpendingAmount && { disabled: true })
        };
      })
    );
  }, [productSubtotalPrice, promotionSuggestions]);

  useEffect(() => {
    const addNewProductToList = () => {
      onProductSelect(newlyCreatedProductId);
      setNewlyCreatedProductId(null);
    };

    const isNewlyAddedProductInSuggestions = productSuggestions && !!productSuggestions.find(product => product.value === newlyCreatedProductId);
    if (isNewlyAddedProductInSuggestions) {
      addNewProductToList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newlyCreatedProductId, productSuggestions]);

  const updateDeliveryProof = async () => {
    if (shouldUsePhotoManager) {
      const [deliveryProof] = await deliveryProofRef.current.update();
      return deliveryProof || null;
    }

    return null;
  };

  return (
    <FullWidthContainer>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Form
          form={form}
          initialValues={orderFormInitialValue}
          scrollToFirstError={true}
          style={{ width: '100%' }}
          onFinish={() =>
            onFormSave({ isUpdateBuyerProfileChecked, addedOrderProducts, promotions, updateDeliveryProof, setIsGeneratingPresaveSummary })
          }
          onFinishFailed={onFormSaveFail}
        >
          <Row gutter={16}>
            <Col span={24} xl={12}>
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  <Card>
                    <Row gutter={16}>
                      <Col span={24}>
                        <BuyerRadioButtons t={t} onChange={onBuyerOptionsChange} value={buyerOption} disabled={!isNew} />
                        {buyerOption === BUYER_OPTION_EXISTING ? (
                          <Row gutter={16}>
                            <Col span={24}>
                              <FormSelection
                                label={t('pageOrderDetails:form-input-label-order-buyer-existing')}
                                name={['customer', '_id']}
                                selections={buyersSelection}
                                filterOption={false}
                                onSearch={value => onSearchBuyer(value)}
                                requiredErrorMessage={t('pageOrderDetails:form-input-required-message-order-buyer-existing')}
                                onChange={onBuyerSuggestionSelect}
                                disabled={!isNew}
                                customMarginBottom={isNew ? 24 : 0}
                              />
                            </Col>
                          </Row>
                        ) : (
                          <Row gutter={16}>
                            <Col span={24}>
                              <FormSelection
                                label={t('pageOrderDetails:form-input-label-order-social-media-user')}
                                name={['customer', 'socialMediaUserId']}
                                selections={socialMediaUsersSelection}
                                filterOption={false}
                                onSearch={value => onSearchSocialMediaUser(value)}
                                onChange={onSocialMediaUserSuggestionSelect}
                              />
                            </Col>
                            <Col span={24}>
                              <FormInput
                                label={t('pageOrderDetails:form-input-label-order-buyer-name')}
                                name={['customer', 'name']}
                                placeholder={t('pageOrderDetails:form-input-placeholder-order-buyer-name')}
                                requiredErrorMessage={t('pageOrderDetails:form-input-required-message-order-buyer-name')}
                              />
                            </Col>
                            <Col span={12}>
                              <FormInput
                                label={t('pageOrderDetails:form-input-label-order-buyer-email')}
                                name={['customer', 'email']}
                                type="email"
                                placeholder={t('pageOrderDetails:form-input-placeholder-order-buyer-email')}
                              />
                            </Col>
                            <Col span={12}>
                              <FormGroupSelectionInput
                                label={t('pageOrderDetails:form-input-label-order-buyer-contact')}
                                inputName={['customer', 'contact', 'contactNumber']}
                                selectionName={['customer', 'contact', 'countryCode']}
                                inputType="contact"
                                selections={countryCodes}
                              />
                            </Col>
                          </Row>
                        )}
                      </Col>
                      {data.customer && (
                        <Col span={24}>
                          <TextButton
                            icon={<FormOutlined />}
                            onClick={() => handleOnCustomerRemarksBtnClick(data.customer._id)}
                            text={t('pageOrderDetails:form-input-label-add-customer-remarks')}
                            style={{ paddingLeft: 0, marginBottom: '24px' }}
                          />
                        </Col>
                      )}
                      <Col span={24}>
                        <FormRadioButton
                          label={t('pageOrderDetails:form-input-label-order-status')}
                          name="status"
                          selections={orderStatuses}
                          buttonStyle="solid"
                        />
                      </Col>
                      <Col span={24}>
                        <FormInput
                          label={t('pageOrderDetails:form-input-label-order-remarks')}
                          name="remarks"
                          placeholder={t('pageOrderDetails:form-input-placeholder-order-remarks')}
                          type="textArea"
                        />
                      </Col>
                      <Col span={24}>
                        <OrderTagSelection name="tags" isAllowNew />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  <Card title={t('pageOrderDetails:card-title-recipient-info')}>
                    <Row gutter={16}>
                      <Col span={24}>
                        {isNew && (
                          <IsBuyerRecipientCheckbox
                            onChange={onIsBuyerTheRecipientCheck}
                            defaultValue={isBuyerTheRecipient}
                            value={isBuyerTheRecipient}
                            checked={isBuyerTheRecipient}
                          >
                            {t('pageOrderDetails:checkbox-recipient-text')}
                          </IsBuyerRecipientCheckbox>
                        )}
                        <IsUpdateBuyerProfileCheckbox
                          onChange={onIsUpdateBuyerProfileCheck}
                          defaultValue={isUpdateBuyerProfileChecked}
                          value={isUpdateBuyerProfileChecked}
                          checked={isUpdateBuyerProfileChecked}
                        >
                          {t('pageOrderDetails:checkbox-update-text')}
                        </IsUpdateBuyerProfileCheckbox>
                      </Col>
                      <Col span={24}>
                        <FormInput
                          label={t('pageOrderDetails:form-input-label-order-recipient-name')}
                          name={['shipment', 'recipientName']}
                          placeholder={t('pageOrderDetails:form-input-placeholder-order-recipient-name')}
                        />
                      </Col>
                      <Col span={24}>
                        <FormGroupSelectionInput
                          label={t('pageOrderDetails:form-input-label-order-recipient-contact')}
                          inputName={['shipment', 'recipientContact', 'contactNumber']}
                          selectionName={['shipment', 'recipientContact', 'countryCode']}
                          inputType="contact"
                          selections={countryCodes}
                        />
                      </Col>
                      <Col span={24}>
                        <FormInput
                          label={t('pageOrderDetails:form-input-label-order-recipient-email')}
                          name={['shipment', 'recipientEmail']}
                          placeholder={t('pageOrderDetails:form-input-placeholder-order-recipient-email')}
                        />
                      </Col>
                      <Col span={24}>
                        <FormInput
                          label={t('pageOrderDetails:form-input-label-order-recipient-address-line-1')}
                          name={['shipment', 'address', 'addressLine1']}
                          placeholder={t('pageOrderDetails:form-input-placeholder-order-recipient-address-line-1')}
                        />
                      </Col>
                      <Col span={24}>
                        <FormInput
                          label={t('pageOrderDetails:form-input-label-order-recipient-address-line-2')}
                          name={['shipment', 'address', 'addressLine2']}
                          placeholder={t('pageOrderDetails:form-input-placeholder-order-recipient-address-line-2')}
                        />
                      </Col>
                      <Col span={12}>
                        <FormInput
                          label={t('pageOrderDetails:form-input-label-order-recipient-city')}
                          name={['shipment', 'address', 'city']}
                          placeholder={t('pageOrderDetails:form-input-placeholder-order-recipient-city')}
                        />
                      </Col>
                      <Col span={12}>
                        <FormInput
                          label={t('pageOrderDetails:form-input-label-order-recipient-zipcode')}
                          name={['shipment', 'address', 'zipcode']}
                          placeholder={t('pageOrderDetails:form-input-placeholder-order-recipient-zipcode')}
                        />
                      </Col>
                      <Col span={12}>
                        <FormInput
                          label={t('pageOrderDetails:form-input-label-order-recipient-state')}
                          name={['shipment', 'address', 'state']}
                          placeholder={t('pageOrderDetails:form-input-placeholder-order-recipient-state')}
                        />
                      </Col>
                      <Col span={12}>
                        <FormSelection
                          label={t('pageOrderDetails:form-input-label-order-recipient-country')}
                          name={['shipment', 'address', 'country']}
                          placeholder={t('pageOrderDetails:form-input-placeholder-order-recipient-country')}
                          selections={countries}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card title={t('pageOrderDetails:card-title-shipping-info')} style={{ height: '100%' }}>
                    <Row gutter={16}>
                      <Col span={24}>
                        <FormSelection
                          label={t('pageOrderDetails:form-input-label-order-shipping-method')}
                          name={['shipment', 'method']}
                          selections={shipmentMethods}
                        />
                      </Col>
                      <Col span={24}>
                        <FormInput
                          label={t('pageOrderDetails:form-input-label-order-shipping-remarks')}
                          name={['shipment', 'remarks']}
                          placeholder={t('pageOrderDetails:form-input-placeholder-order-shipping-remarks')}
                          type="textArea"
                        />
                      </Col>
                      <Col span={12}>
                        <FormInput label={t('pageOrderDetails:form-input-label-order-shipping-courier')} name={['shipment', 'courier']} />
                      </Col>
                      <Col span={12}>
                        <FormInput
                          label={t('pageOrderDetails:form-input-label-order-shipping-tracking-number')}
                          name={['shipment', 'trackingNo']}
                          placeholder={'e.g.: VNUG7G'}
                        />
                      </Col>
                      <Col span={12}>
                        <FormInput
                          label={t('pageOrderDetails:form-input-label-order-shipping-tracking-link')}
                          name={['shipment', 'trackingLink']}
                          placeholder={'e.g.: https://www.tracking.my/flash/VNUG7G'}
                        />
                      </Col>
                      <Col span={12}>
                        {shouldUsePhotoManager ? (
                          <PhotoManager
                            value={currentDeliveryProof}
                            onChange={setCurrentDeliveryProof}
                            label={t('modalOrderUpdateShipment:form-input-label-shipment-delivery-proof')}
                            buttonLabel={t('modalOrderUpdateShipment:form-submit-upload-button-text')}
                            fileSizeThresholdToCompressInKb={200}
                            shouldRemoveDocsMarkedForDelete
                            ref={deliveryProofRef}
                            storeId={store._id}
                          />
                        ) : (
                          <FormUpload
                            name={['shipment', 'deliveryProof']}
                            label={t('modalOrderUpdateShipment:form-input-label-shipment-delivery-proof')}
                            buttonLabel={t('modalOrderUpdateShipment:form-submit-upload-button-text')}
                            acceptTypes={['image/*']}
                            fileSizeLimitInMb={10}
                            isMultiple={false}
                            isDisplayCard
                          />
                        )}
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col span={24} xl={12}>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Card>
                    <FormSelection
                      label={
                        <Row justify="space-between" align="middle">
                          {t('pageOrderDetails:form-input-label-order-add-product')}
                          <div justify="space-between">
                            {!isNew && (
                              <Button icon={<SplitCellsOutlined />} type="link" onClick={onSplitOrderBtnClick}>
                                {t('pageOrderDetails:form-input-label-order-split-products')}
                              </Button>
                            )}
                            <Button icon={<PlusOutlined />} type="link" onClick={onOpenQuickAddProductModal}>
                              {t('pageOrderDetails:form-input-label-order-quick-add-new-product')}
                            </Button>
                          </div>
                        </Row>
                      }
                      name="orderProduct"
                      filterOption={onSearchProduct ? false : undefined}
                      onSearch={onSearchProduct}
                      selections={productSuggestions}
                      isSearching={isProductSuggestionsLoading}
                      onChange={onProductSelect}
                    />
                    <h3>
                      {t('pageOrderDetails:order-product-subtotal-text')}{' '}
                      <span style={{ fontWeight: 700 }}>
                        <PriceDisplay amount={productSubtotalPrice} />
                      </span>
                      <Tooltip title={t('pageOrderDetails:order-product-tooltip-message-subtotal-disclaimer')}>
                        <InfoCircleFilled style={{ marginLeft: '4px' }} />
                      </Tooltip>
                    </h3>
                    <Card>
                      <List
                        rowKey={record => record._id}
                        dataSource={addedOrderProducts}
                        renderItem={addedOrderProduct => (
                          <List.Item
                            actions={[
                              <TextButton
                                icon={<DeleteOutlined />}
                                onClick={() => onProductDelete(addedOrderProduct._id)}
                                text={t('pageOrderDetails:order-product-text-button-remove')}
                                tooltipMessage={t('common:text-button-tooltip-message', {
                                  action: t('pageOrderDetails:order-product-tooltip-message-action-remove')
                                })}
                              />
                            ]}
                          >
                            <List.Item.Meta
                              title={
                                <span>
                                  {addedOrderProduct.label}{' '}
                                  {addedOrderProduct.isDeleted && <Tag color="default">{t('pageOrderDetails:order-product-deleted-text-label')}</Tag>}
                                </span>
                              }
                              description={
                                <PriceDisplay
                                  prefix={`${addedOrderProduct.orderKeyword} | ${addedOrderProduct.quantity || 1} x `}
                                  amount={addedOrderProduct.price || 0}
                                />
                              }
                            />
                            <Row justify="end" style={{ width: '30%' }}>
                              <div style={{ width: '50%', marginBottom: '-24px' }}>
                                <FormInputNumber
                                  name={`${addedOrderProduct.value}-quantity`}
                                  placeholder={t('pageOrderDetails:order-product-input-placeholder-product-quantity')}
                                  requiredErrorMessage={t('pageOrderDetails:order-product-input-required-message-product-quantity')}
                                  value={addedOrderProduct.quantity}
                                  onChange={value => onProductQtyChange(value, addedOrderProduct._id)}
                                  minValue={1}
                                  maxValue={addedOrderProduct.inventory}
                                />
                              </div>
                            </Row>
                          </List.Item>
                        )}
                      />
                    </Card>
                  </Card>
                </Col>
                <Col span={24} lg={12}>
                  <Card title={t('pageOrderDetails:card-title-discount-info')} style={{ height: '100%' }}>
                    <FormInputNumber
                      label={t('pageOrderDetails:form-input-label-order-discount-amount')}
                      name={['discount', 'amount']}
                      type="financial"
                      placeholder={t('pageOrderDetails:form-input-placeholder-order-discount-amount')}
                    />
                    <FormInput
                      label={t('pageOrderDetails:form-input-label-order-discount-remarks')}
                      name={['discount', 'remarks']}
                      placeholder={t('pageOrderDetails:form-input-placeholder-order-discount-remarks')}
                      type="textArea"
                    />
                  </Card>
                </Col>
                <Col span={24} lg={12}>
                  <Card title={t('pageOrderDetails:card-title-extra-fee-info')} style={{ height: '100%' }}>
                    <FormInputNumber
                      label={t('pageOrderDetails:form-input-label-order-extra-fee-amount')}
                      name={['extraFee', 'amount']}
                      type="financial"
                      placeholder={t('pageOrderDetails:form-input-placeholder-order-extra-fee-amount')}
                    />
                    <FormInput
                      label={t('pageOrderDetails:form-input-label-order-extra-fee-remarks')}
                      name={['extraFee', 'remarks']}
                      placeholder={t('pageOrderDetails:form-input-placeholder-order-extra-fee-remarks')}
                      type="textArea"
                    />
                  </Card>
                </Col>
                <PromotionInfoCard
                  t={t}
                  isPromotionSuggestionsLoading={isPromotionSuggestionsLoading}
                  promotionSuggestions={formattedPromotionSuggestions}
                  promotions={promotions}
                  storeCurrency={storeCurrency}
                  invalidVoucherIds={invalidVoucherIds}
                  onRemoveClick={handleOnVoucherRemove}
                  onPromoSelect={handleOnPromoCodeAdd}
                />
                {!isNew && (
                  <Col span={24}>
                    <Card title={t('pageOrderDetails:card-title-payment-info')} style={{ height: '100%' }}>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
                        <Button icon={<PlusOutlined />} type="primary" onClick={onAddNewPaymentClick}>
                          {t('pageOrderDetails:payment-info-add-new-button')}
                        </Button>
                      </div>
                      <Table
                        rowKey={record => record._id}
                        columns={constructPaymentTableColumns({ t })}
                        dataSource={orderPayments}
                        pagination={false}
                        scroll={{ x: 'fit-content' }}
                      />
                      {showPaymentModal && (
                        <PaymentInfoModal
                          visible={showPaymentModal}
                          orderId={orderId}
                          paymentId={selectedPaymentId}
                          balanceAmount={orderFormInitialValue && orderFormInitialValue.balanceAmount}
                          onClose={onClosePaymentModal}
                        />
                      )}
                    </Card>
                  </Col>
                )}
                {isCustomerRemarksModalVisible && (
                  <CustomerRemarksModal
                    isCustomerRemarksModalVisible={isCustomerRemarksModalVisible}
                    setIsCustomerRemarksModalVisible={setIsCustomerRemarksModalVisible}
                    selectedCustomerId={selectedCustomerId}
                  />
                )}
                {showQuickAddProductModal && (
                  <QuickAddProductModal
                    visible={showQuickAddProductModal}
                    onClose={onCloseQuickAddProductModal}
                    onProductCreated={handleOnProductCreated}
                  />
                )}
              </Row>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col>
              <Button htmlType="submit" type="primary" icon={<CheckOutlined />} loading={isButtonSaveLoading}>
                {isNew ? t('common:form-submit-create-button-text') : t('common:form-submit-update-button-text')}
              </Button>
            </Col>
            {!isNew && (
              <Col>
                <Button type="danger" icon={<CloseOutlined />} onClick={onCancel}>
                  {t('pageOrderDetails:header-action-button-cancel-order')}
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      )}
    </FullWidthContainer>
  );
};

export default withAppContext(OrderForm);
