import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PiBookmarksLight } from 'react-icons/pi';
import debounce from 'lodash.debounce';

import FormSelection from 'components/FormSelection/FormSelection';
import FormMultiInputTag from 'components/FormMultiInputTag/FormMultiInputTag';

import { useGetStoreOrderTagSuggestions } from 'apis/order';

const OrderTagSelection = ({ name, isAllowNew }) => {
  const { t } = useTranslation(['pageOrder']);
  const [searchTagKeyword, setSearchTagKeyword] = useState('');
  const delaySetSearchTagKeyword = useMemo(() => debounce(setSearchTagKeyword, 300), []);
  const { isLoading: isStoreOrderTagSuggestionsLoading, data: storeOrderTagsSuggestions } = useGetStoreOrderTagSuggestions(searchTagKeyword);

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      <PiBookmarksLight style={{ fontSize: '20px', color: '#797979' }} />
      {isAllowNew ? (
        <FormMultiInputTag
          name={name}
          placeholder={t('pageOrder:select-input-placeholder-order-tags')}
          isMultiple
          isShowDropdown
          selections={storeOrderTagsSuggestions ? storeOrderTagsSuggestions : []}
          isSearching={isStoreOrderTagSuggestionsLoading}
          onSearch={delaySetSearchTagKeyword}
          onSelect={() => {
            setSearchTagKeyword('');
          }}
          style={{ marginBottom: '0' }}
        />
      ) : (
        <FormSelection
          name={name}
          placeholder={t('pageOrder:select-input-placeholder-order-tags')}
          isMultiple
          selections={storeOrderTagsSuggestions ? storeOrderTagsSuggestions : []}
          isSearching={isStoreOrderTagSuggestionsLoading}
          onSearch={delaySetSearchTagKeyword}
          onSelect={() => {
            setSearchTagKeyword('');
          }}
          customMarginBottom="0"
        />
      )}
    </div>
  );
};

OrderTagSelection.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  isAllowNew: PropTypes.bool
};

OrderTagSelection.defaultProps = {
  isAllowNew: false
};

export default OrderTagSelection;
