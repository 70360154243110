import { useState } from 'react';
import { MessageOutlined, ToTopOutlined } from '@ant-design/icons';
import { Button, Form, message } from 'antd';
import { useTranslation } from 'react-i18next';

import { postPublishCommentToPost } from 'apis/post';

import { logError } from 'utils/logging';

import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import FormInput from 'components/FormInput/FormInput';

import { FormContentCommentContainer, FormActionButtonsContainer } from './PostCommentAndBumpKeywords.styles';
import ProductKeywordModal from '../ProductKeywordModal/ProductKeywordModal';

const { useForm } = Form;

const PostCommentAndBumpKeywords = ({ postId, allowCommentAction = true }) => {
  const { t } = useTranslation(['common', 'pageOverview']);
  const [form] = useForm();
  const [isBumpKeywordsClicked, setIsBumpKeywordsClicked] = useState(false);
  const [isPostingComment, setIsPostingComment] = useState(false);
  const [isPostingKeywords, setIsPostingKeywords] = useState(false);

  const handleOnPostCommentClick = () => {
    const comment = form.getFieldValue('comment');
    if (comment) {
      setIsPostingComment(true);
      postPublishCommentToPost(postId, { message: comment })
        .then(() => {
          message.success(t('pageOverview:live-comment-post-success-message'));
          setIsPostingComment(false);
          form.resetFields();
        })
        .catch(error => {
          logError(error);
          message.error(t('pageOverview:live-comment-post-fail-message'));
          setIsPostingComment(false);
        });
    }
  };

  const handleOnBumpKeywordsClick = () => {
    setIsBumpKeywordsClicked(true);
  };

  const handleOnPostKeywords = comment => {
    if (comment) {
      setIsPostingKeywords(true);
      postPublishCommentToPost(postId, { message: comment })
        .then(() => {
          message.success(t('pageOverview:live-comment-post-success-message'));
          setIsPostingKeywords(false);
          // TEMP: DISABLE THIS
          // setIsBumpKeywordsClicked(false);
          form.resetFields();
        })
        .catch(error => {
          logError(error);
          message.error(t('pageOverview:live-comment-post-fail-message'));
          setIsPostingKeywords(false);
        });
    }
  };

  return (
    <FullWidthContainer>
      {allowCommentAction && (
        <Form form={form}>
          <FormContentCommentContainer>
            <FormInput
              label={t('pageOverview:form-input-label-live-comment')}
              name="comment"
              placeholder={t('pageOverview:form-input-placeholder-live-comment')}
              type="textArea"
              rows={2}
              extraProps={{
                maxLength: 2000,
                autoSize: {
                  minRows: 4
                },
                showCount: true
              }}
            />
            <FormActionButtonsContainer>
              <Button
                type="secondary"
                icon={<ToTopOutlined />}
                style={{ marginRight: '4px', color: '#2c278a', borderColor: '#2c278a' }}
                onClick={handleOnBumpKeywordsClick}
                disabled={isPostingComment}
              >
                {t('pageOverview:form-action-button-bump-keywords')}
              </Button>
              <Button type="primary" icon={<MessageOutlined />} onClick={handleOnPostCommentClick} loading={isPostingComment}>
                {t('pageOverview:form-action-button-post-comment')}
              </Button>
            </FormActionButtonsContainer>
          </FormContentCommentContainer>
        </Form>
      )}
      {isBumpKeywordsClicked && (
        <ProductKeywordModal
          visible={isBumpKeywordsClicked}
          onClose={() => setIsBumpKeywordsClicked(false)}
          onPost={handleOnPostKeywords}
          isBtnLoading={isPostingKeywords}
        />
      )}
    </FullWidthContainer>
  );
};

export default PostCommentAndBumpKeywords;
