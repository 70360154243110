import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  DollarCircleOutlined,
  EditOutlined,
  EyeOutlined,
  RetweetOutlined,
  MergeCellsOutlined,
  PlusOutlined,
  PrinterOutlined,
  SplitCellsOutlined,
  TagsOutlined,
  RightOutlined
} from '@ant-design/icons';

import {
  Button,
  Col,
  Checkbox,
  Dropdown,
  Menu,
  message,
  Radio,
  Row,
  Skeleton,
  Space,
  Table,
  Tag,
  notification,
  Tooltip,
  Popconfirm,
  Avatar
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Helmet as ReactHelmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { PiBookmarksLight } from 'react-icons/pi';
import debounce from 'lodash.debounce';

import {
  postCreateStoreOrderTags,
  patchCancelOrder,
  patchOrderStatus,
  useGetOrders,
  useGetOrderDetails,
  useGetStoreOrderTagSuggestions,
  patchConfirmPaymentOrder
} from 'apis/order';
import { useGetShipments } from 'apis/shipment';
import { patchSocialMediaUserBlacklistStatus } from 'apis/socialMediaUser';

import { withAppContext } from 'contexts/AppContext/AppContext';

import { useFetchConstant } from 'hooks/constants';

import { DEFAULT_PAGINATION_QUERY, getOrderExtCheckoutLink } from 'utils/constants';
import { getLabelOfConstant, guard } from 'utils/general';
import { formatToDateTimeWithPMAM } from 'utils/date';
import { getNewOrderRoute, getOrderDetailRoute, getBulkPrintOrdersRoute, getSubscriptionPlanRoute } from 'utils/routes';
import {
  constructColumn,
  constructColumnFilterSearch,
  handleOnAggregationTableChange,
  constructColumnFilterRadio,
  formatPaginationShowTotalDisplay
} from 'utils/table/table';
import { useIsLoadings } from 'hooks/utils';

import CancelOrderModal from 'components/CancelOrderModal/CancelOrderModal';
import CustomerMergeModal, { useCustomerMergeModal } from 'components/CustomerMergeModal/CustomerMergeModal';
import CustomerRemarksModal, { useCustomerRemarksModal } from 'components/CustomerRemarksModal/CustomerRemarksModal';
import FBModal, { useFBModal } from 'components/FBModal/FBModal';
import FormSelection from 'components/FormSelection/FormSelection';
import FullWidthContainer from 'components/FullWidthContainer/FullWidthContainer';
import Logo from 'images/u-logo.png';
import OrderSummaryAndProductsModal from 'components/OrderSummaryAndProductsModal/OrderSummaryAndProductsModal';
import PriceDisplay from 'components/PriceDisplay/PriceDisplay';
import ShippingFeeIcon from 'components/Icon/ShippingFeeIcon';
import StatusUpdateModal from 'components/StatusUpdateModal/StatusUpdateModal';
import TextButton from 'components/TextButton/TextButton';
import Title from 'components/Title/Title';
import CustomerTier from 'components/CustomerTier/CustomerTier';

import BulkUploadModal from './components/BulkUploadModal/BulkUploadModal';
import ExportShipmentCSVModal from './components/ExportShipmentCSVModal/ExportShipmentCSVModal';
import ExportOrdersCSVModal from './components/ExportOrdersCSVModal/ExportOrdersCSVModal';
import ExportPaymentsCSVModal from './components/ExportPaymentsCSVModal/ExportPaymentsCSVModal';
import OrderMergeModal, { useOrderMergeModal } from './components/OrderMergeModal/OrderMergeModal';
import OrderSplitModal, { useOrderSplitModal } from './components/OrderSplitModal/OrderSplitModal';
import OrderUpdateShipmentModal from './components/OrderUpdateShipmentModal/OrderUpdateShipmentModal';
import OrderViewPaymentModal from './components/OrderViewPaymentModal/OrderViewPaymentModal';
import PrintBulkOrdersModal from './components/PrintBulkOrdersModal/PrintBulkOrdersModal';

import {
  DownloadCSVButton,
  TitleContainer,
  OrderProductsContainer,
  RemarksClickableIcon,
  WAClickableIcon,
  BalanceAmountText,
  StyledTextLink,
  StyledLastUpdatedText,
  FBClickableIcon,
  UliveAccountIcon,
  NameLabel,
  StyledShipmentDetail
} from './Orders.styles';

const { Group: CheckboxGroup } = Checkbox;
const { Group: RadioGroup, Button: RadioButton } = Radio;
const STATUS_VIEW_OPTION_ALL = 'all';
const SOURCE_VIEW_OPTION_ALL = 'all';

/* =================================================== Local Functions =================================================== */
// =================================================== General
const constructColumns = ({
  t,
  store,
  selectedStatusView,
  orderStatuses = [],
  orderStatusesConst = {},
  socialMediaSourcesConst = {},
  shipmentSelections = [],
  balanceStatuses = [],
  isRoleAllowUpdate,
  handleOnWABtnClick,
  handleOnFBBtnClick,
  handleOnCustomerRemarksBtnClick,
  handleOnCopyCheckoutLinkButtonClick,
  handleOnOpenCancelOrderModal,
  handleOnViewPaymentBtnClick,
  handleOnViewSummaryBtnClick,
  handleOnUpdateShippingBtnClick,
  handleOnBlacklistModalConfirm,
  handleOnCopyOrderSummaryBtnClick
}) => [
  {
    ...constructColumn(t('pageOrder:table-header-order-number'), 'orderNumber', {
      hasAggregationSorter: true,
      width: '220px'
    }),
    ...constructColumnFilterSearch('orderNumber', t('pageOrder:table-header-action-search-order-number'), {
      hasAggregationFilter: true
    }),
    render: (orderNumber, record) => {
      const lastUpdatedDate = record.updatedAt || record.purchasedDate;
      const showPreservedOrderIcon =
        record.isPreserved || (record.status === guard(() => orderStatusesConst.CHECKOUT.code) && record.isPreservePending);
      const showIsPrintedOrderIcon = record.isPrinted;
      return (
        <div>
          <Space>
            {showPreservedOrderIcon && (
              <Tooltip title={t('pageOrder:order-preserved-icon-tooltip-message')} placement="top">
                <DollarCircleOutlined style={{ color: '#ffba00' }} />
              </Tooltip>
            )}
            {showIsPrintedOrderIcon && (
              <Tooltip title={t('pageOrder:order-printed-icon-tooltip-message')} placement="top">
                <PrinterOutlined style={{ color: '#797979' }} />
              </Tooltip>
            )}
            <StyledTextLink onClick={() => handleOnViewSummaryBtnClick(record._id)}>{orderNumber}</StyledTextLink>
            {record.status !== guard(() => orderStatusesConst.CANCELLED.code, '') &&
              record.status !== guard(() => orderStatusesConst.COMPLETED.code, '') && (
                <StyledTextLink onClick={() => window.open(getOrderDetailRoute(record._id).path)}>
                  <EditOutlined />
                </StyledTextLink>
              )}
          </Space>
          {selectedStatusView === guard(() => orderStatusesConst.PENDING.code, '') && (
            <>
              <br />
              <StyledLastUpdatedText>
                {t('pageOrder:table-content-last-updated', { updatedDateTime: formatToDateTimeWithPMAM(lastUpdatedDate) })}
              </StyledLastUpdatedText>
            </>
          )}
        </div>
      );
    }
  },
  {
    ...constructColumn(t('pageOrder:table-header-order-buyer'), 'customerDisplayInfo', {
      width: '220px'
    }),
    ...constructColumnFilterSearch('customerDisplayInfo', t('pageOrder:table-header-action-search-order-buyer'), { hasAggregationFilter: true }),
    render: (customerInfo, record) => {
      const hasCustomerRemarks = record.customerRemarks;
      const hasContactNumber = !!record.customerContactNumber;
      const hasCustomerSource = record.customerSource;
      const hasCustomerTier = record.customerTier;
      const isFBCustomer = record.customerSource === guard(() => socialMediaSourcesConst.FB.code, '');
      const hasUliveAccount = record.isBuyerUser;
      const paymentReminderCount = record.paymentReminderCount || 0;

      const customerId = record.customerId;
      const title = !record.customerIsBlacklisted
        ? t('pageOrder:order-user-blacklist-title-blacklist')
        : t('pageOrder:order-user-blacklist-title-unblacklist');
      const okText = !record.customerIsBlacklisted
        ? t('pageOrder:order-user-blacklist-button-blacklist')
        : t('pageOrder:order-user-blacklist-button-unblacklist');
      const cancelText = t('common:modal-cancel-text');
      return (
        <Tooltip title={record.customerIsBlacklisted ? t('pageOrder:order-user-blacklist-tooltip') : undefined} zIndex="1055">
          {hasCustomerTier && <CustomerTier key={record.customerTier.label} emoji={record.customerTier.emoji} label={record.customerTier.label} />}
          {hasCustomerSource && isRoleAllowUpdate ? (
            <Popconfirm
              title={title}
              okText={okText}
              cancelText={cancelText}
              onConfirm={handleOnBlacklistModalConfirm(record.customerSourceId, record.customerSource, !record.customerIsBlacklisted)}
            >
              {record.customerProfilePicUrl && (
                <>
                  <Avatar size="small" src={`${record.customerProfilePicUrl}`} />{' '}
                </>
              )}
              <NameLabel isUserBlacklisted={record.customerIsBlacklisted}>
                {record.customerName} {`(${record.customerSource.toUpperCase()}: ${record.customerSourceName})`}
              </NameLabel>{' '}
              {record.customerContactNumber && <span> ({record.customerContactNumber}) </span>}
            </Popconfirm>
          ) : (
            <span>{customerInfo} </span>
          )}{' '}
          {hasCustomerRemarks && <RemarksClickableIcon width={14} height={14} onClick={() => handleOnCustomerRemarksBtnClick(customerId)} />}
          {hasContactNumber && <WAClickableIcon onClick={() => handleOnWABtnClick(record.customerContactNumber)} />}
          {isFBCustomer && (
            <FBClickableIcon width={14} height={14} onClick={() => handleOnFBBtnClick(customerId, constructOrderSummary(record, store))} />
          )}
          {hasUliveAccount && (
            <Tooltip
              title={t('pageOrder:tooltip-message-payment-reminder-icon', { reminderCount: paymentReminderCount })}
              trigger="click"
              arrowPointAtCenter
            >
              <UliveAccountIcon src={Logo} shape="square" size={14} />
            </Tooltip>
          )}
        </Tooltip>
      );
    }
  },
  {
    ...constructColumn(t('pageOrder:table-header-order-products'), 'products', { width: '12%' }),
    ...constructColumnFilterSearch('products', t('pageOrder:table-header-action-search-products'), { hasAggregationFilter: true }),
    render: (text, record) => {
      return (
        <OrderProductsContainer>
          {record.products.map(orderProduct => (
            <span key={orderProduct.matchingKeyword}>
              {orderProduct.matchingKeyword} x {orderProduct.quantity}
            </span>
          ))}
        </OrderProductsContainer>
      );
    }
  },
  {
    ...constructColumn(t('pageOrder:table-header-order-total-amount'), 'totalAmount', {
      hasAggregationSorter: true,
      isPrice: true,
      width: '12%'
    }),
    ...constructColumnFilterSearch('totalAmount', t('pageOrder:table-header-order-total-amount'), { hasAggregationFilter: true })
  },
  {
    ...constructColumn(t('pageOrder:table-header-order-balance-amount'), 'balanceAmount', {
      hasAggregationSorter: true,
      isPrice: true,
      width: '10%'
    }),
    ...constructColumnFilterRadio('balanceAmount', balanceStatuses, { hasAggregationFilter: true }),
    render: balanceAmount => {
      return (
        <BalanceAmountText balanceAmount={balanceAmount}>
          <PriceDisplay amount={balanceAmount} />
        </BalanceAmountText>
      );
    }
  },
  {
    ...(selectedStatusView === guard(() => orderStatusesConst.CANCELLED.code, '')
      ? {
          ...constructColumn(t('pageOrder:table-header-order-cancelled-at'), 'updatedAt', { width: '11%', hasAggregationSorter: true }),
          render: date => formatToDateTimeWithPMAM(date)
        }
      : {
          ...constructColumn(t('pageOrder:table-header-order-shipment'), 'shipment', { width: '11%' }),
          ...constructColumnFilterRadio('shipment', shipmentSelections, { hasAggregationFilter: true }),
          render: (shipment, record) => {
            return (
              <div>
                {shipment}
                {guard(() => record.status === orderStatusesConst.COMPLETED.code, false) && (record.courier || record.trackingNo) && (
                  <>
                    <br />
                    <StyledShipmentDetail>
                      {record.courier}
                      {record.trackingNo && (
                        <>
                          {record.courier && <br />}
                          {record.trackingNo}
                        </>
                      )}
                    </StyledShipmentDetail>
                  </>
                )}
              </div>
            );
          }
        })
  },
  {
    ...constructColumn(t('pageOrder:table-header-order-purchased-date'), 'purchasedDate', { hasAggregationSorter: true, width: '120px' }),
    render: date => formatToDateTimeWithPMAM(date)
  },
  {
    ...(selectedStatusView === STATUS_VIEW_OPTION_ALL && {
      ...constructColumn(t('pageOrder:table-header-order-status'), 'status'),
      ...constructColumnFilterRadio('status', orderStatuses),
      render: status => getLabelOfConstant(status, orderStatuses)
    })
  },
  {
    ...constructColumn(t('pageOrder:table-header-order-remarks'), 'shipmentRemarks'),
    ...constructColumnFilterSearch('shipmentRemarks', t('pageOrder:table-header-order-remarks'), { hasAggregationFilter: true }),
    render: (text, record) => {
      return (
        <div>
          <p style={{ marginBottom: 0 }}>{text || '-'}</p>
          {record.tags?.map(tag => {
            return <Tag>{tag.title}</Tag>;
          })}
        </div>
      );
    }
  },
  {
    ...(selectedStatusView !== guard(() => orderStatusesConst.COMPLETED.code, '') &&
      isRoleAllowUpdate && {
        ...constructColumn(t('pageOrder:table-header-actions'), 'action', { width: '100px' }),
        render: (text, record) => (
          <Space direction="vertical" size="small">
            {(record.status === guard(() => orderStatusesConst.PENDING.code, '') ||
              record.status === guard(() => orderStatusesConst.PRE_CHECKOUT.code, '')) && (
              <TextButton
                size="small"
                icon={<CopyOutlined />}
                onClick={() => handleOnCopyCheckoutLinkButtonClick(record)}
                text={t('pageOrder:table-action-button-copy-checkout-link')}
              />
            )}
            {(record.status === guard(() => orderStatusesConst.CHECKOUT.code, '') ||
              (record.isPreserved && record.status === guard(() => orderStatusesConst.PENDING.code, ''))) && (
              <TextButton
                size="small"
                icon={<DollarCircleOutlined />}
                onClick={() => handleOnViewPaymentBtnClick(record._id)}
                text={t('pageOrder:table-action-button-view-payment')}
              />
            )}
            {record.status === guard(() => orderStatusesConst.PAID.code, '') && (
              <>
                <TextButton
                  size="small"
                  icon={<ShippingFeeIcon type="link" width={20} height={20} style={{ marginRight: '6px', marginBottom: '2px' }} />}
                  onClick={() => handleOnUpdateShippingBtnClick(record._id)}
                  text={t('pageOrder:table-action-button-update-shipping')}
                />
              </>
            )}
            {(record.status === guard(() => orderStatusesConst.PENDING.code, '') ||
              record.status === guard(() => orderStatusesConst.PRE_CHECKOUT.code, '') ||
              record.status === guard(() => orderStatusesConst.CHECKOUT.code, '') ||
              record.status === guard(() => orderStatusesConst.PAID.code, '') ||
              record.status === guard(() => orderStatusesConst.PARTIALLY_SHIPPED.code, '')) && (
              <TextButton
                size="small"
                icon={<CopyOutlined />}
                onClick={() => handleOnCopyOrderSummaryBtnClick(record)}
                text={t('pageOrder:table-action-button-copy-order-summary')}
              />
            )}
            {record.status === guard(() => orderStatusesConst.CANCELLED.code, '') && (
              <>
                <TextButton
                  size="small"
                  icon={<EyeOutlined />}
                  onClick={() => handleOnOpenCancelOrderModal(record._id)}
                  text={t('pageOrder:table-action-button-view-remarks')}
                />
              </>
            )}
          </Space>
        )
      })
  }
];

const constructOrderSummary = (order, store) => {
  const { orderNumber, totalAmount, balanceAmount, accessCode } = order;

  const products = order.products.map(product => {
    const { matchingKeyword, label, quantity, priceDetails } = product;
    const totalAmount = guard(() => priceDetails.totalAmount);
    const currency = guard(() => store.currency.symbol);

    return `${matchingKeyword} - ${label} x ${quantity} ${currency} ${totalAmount}`;
  });

  const currency = guard(() => store.currency.symbol);

  const sellerBankInfo = {
    bankName: guard(() => store.bankDetails.bankName, '-'),
    accountNumber: guard(() => store.bankDetails.accountNumber, '-'),
    beneficiaryName: guard(() => store.bankDetails.beneficiaryName || '-', '-')
  };

  const link = getOrderExtCheckoutLink(orderNumber, accessCode);
  const endingMessage = store.customEndingMessageOrderSummary || 'Thank you';

  return `Summary of your order ${orderNumber}\n\nProducts:\n${products.join(
    '\n'
  )}\n\nTotal (include all fees and discount if applicable): ${currency} ${totalAmount}\nBalance to pay: ${currency} ${balanceAmount}\n\nPay:\nBank: ${
    sellerBankInfo.bankName
  }\nAccount Number: ${sellerBankInfo.accountNumber}\nBeneficiary Name: ${sellerBankInfo.beneficiaryName}\n\n${endingMessage}\n\n${link}`;
};

// =================================================== Hook Functions
const useFetchConstants = () => {
  const { selection: countries, isLoading: isCountriesLoading } = useFetchConstant('countries', {
    labelKey: 'name',
    valueKey: 'iso2'
  });
  const { data: orderStatusesConst, selection: orderStatuses, isLoading: isOrderStatusesLoading } = useFetchConstant('orderStatuses');
  const { selection: orderSources, isLoading: isOrderSourcesLoading } = useFetchConstant('orderSources');
  const { selection: paymentMethods, isLoading: isPaymentMethodsLoading } = useFetchConstant('paymentMethods');
  const { selection: balanceStatuses, isLoading: isBalanceStatusesLoading } = useFetchConstant('balanceStatuses');
  const { data: productTypesConst, isLoading: isProductTypesLoading } = useFetchConstant('productTypes');
  const { data: socialMediaSourcesConst, isLoading: isSocialMediaSourcesLoading } = useFetchConstant('socialMediaSources');

  const { isLoading: isLoadingConstants } = useIsLoadings([
    isCountriesLoading,
    isOrderStatusesLoading,
    isOrderSourcesLoading,
    isPaymentMethodsLoading,
    isProductTypesLoading,
    isSocialMediaSourcesLoading,
    isBalanceStatusesLoading
  ]);

  return {
    isLoadingConstants,
    countries,
    orderStatusesConst,
    orderStatuses,
    orderSources,
    paymentMethods,
    balanceStatuses,
    productTypesConst,
    socialMediaSourcesConst
  };
};

const useBulkActions = ({ t, orderStatusesConst, selectedStatusView, onAfterBulkUpdateSuccess }) => {
  const [isCancelOrdersClicked, setIsCancelOrdersClicked] = useState(false);
  const [isUpdateOrdersClicked, setIsUpdateOrdersClicked] = useState(false);
  const [isBulkPrintOrdersClicked, setIsBulkPrintOrdersClicked] = useState(false);

  const [isUpdatingOrders, setIsUpdatingOrders] = useState(false);
  const [isCancellingOrders, setIsCancellingOrders] = useState(false);

  const [selectedOrderIds, setSelectedOrderIds] = useState([]);

  const handleOnCancelMultipleOrders = useCallback(
    payload => {
      setIsCancellingOrders(true);

      Promise.all(
        selectedOrderIds.map(selectedOrderId =>
          patchCancelOrder(selectedOrderId, payload)
            .then(() => {
              message.success(t('pageOrder:modal-cancel-order-success-message'));
            })
            .catch(ex => {
              message.error(t('common:update-fail-message'));
            })
        )
      ).then(() => {
        setIsCancelOrdersClicked(false);
        setIsCancellingOrders(false);
        setSelectedOrderIds([]);
        onAfterBulkUpdateSuccess();
      });
    },
    [t, selectedOrderIds, onAfterBulkUpdateSuccess]
  );

  const handleOnUpdateMultipleOrders = useCallback(
    async payload => {
      setIsUpdatingOrders(true);
      const { isSettleBalanceChecked, remarks, tags, ...otherPayload } = payload;

      const hasOrderTag = tags?.length > 0;
      const tagIds = hasOrderTag ? await postCreateStoreOrderTags({ tags }) : [];

      Promise.all(
        selectedOrderIds.map(selectedOrderId =>
          patchOrderStatus(selectedOrderId, {
            ...otherPayload,
            remarks: undefined,
            ...(remarks && { remarks }),
            ...(hasOrderTag && { tags: tagIds })
          })
            .then(async () => {
              if ([orderStatusesConst.PAID.code, orderStatusesConst.COMPLETED.code].includes(otherPayload.status)) {
                await patchConfirmPaymentOrder(selectedOrderId, { isSettleBalanceChecked });
              }
              message.success(t('pageOrder:modal-update-status-success-message'));
            })
            .catch(() => {
              message.error(t('common:update-fail-message'));
            })
        )
      ).then(() => {
        if (otherPayload.status !== selectedStatusView) {
          setSelectedOrderIds([]);
        }
        setIsUpdateOrdersClicked(false);
        setIsUpdatingOrders(false);
        onAfterBulkUpdateSuccess();
      });
    },
    [t, orderStatusesConst, selectedStatusView, selectedOrderIds, onAfterBulkUpdateSuccess]
  );

  const handleOnPrintBulkOrders = useCallback(
    printModeIsCompact => {
      window.open(getBulkPrintOrdersRoute(selectedOrderIds, printModeIsCompact).path);
      setIsBulkPrintOrdersClicked(false);
    },
    [selectedOrderIds]
  );

  return {
    isCancelOrdersClicked,
    setIsCancelOrdersClicked,

    isUpdateOrdersClicked,
    setIsUpdateOrdersClicked,

    isBulkPrintOrdersClicked,
    setIsBulkPrintOrdersClicked,

    isUpdatingOrders,
    isCancellingOrders,

    selectedOrderIds,
    setSelectedOrderIds,

    handleOnCancelMultipleOrders,
    handleOnUpdateMultipleOrders,
    handleOnPrintBulkOrders
  };
};

/* =================================================== Local Components =================================================== */
const Helmet = ({ t }) => {
  return (
    <ReactHelmet>
      <meta name="title" id="gtm-title" content="Orders" />
      <title>{t('pageOrder:page-title')}</title>
    </ReactHelmet>
  );
};

const GeneralActionRow = ({
  t,
  isRoleAllowOperation,
  isRoleAllowPrintInvoiceAndDownloadBasicCSV,
  isRoleAllowUpdate,
  setIsExportShipmentCSVClicked,
  setIsExportCSVClicked,
  setIsExportPaymentsCSVClicked,
  setIsBulkUploadClicked,
  onMergeCustomerBtnClick,
  onMergeOrderBtnClick,
  onSplitOrderBtnClick,
  onCheckCanCreateOrder
}) => {
  return (
    <TitleContainer justify="space-between">
      <Title>{t('pageOrder:title')}</Title>
      <Space>
        {isRoleAllowOperation && (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item icon={<DownloadOutlined />} onClick={() => setIsExportShipmentCSVClicked(true)}>
                  {t('pageOrder:download-prefill-shipment-button')}
                </Menu.Item>
                <Menu.Item icon={<EditOutlined />} onClick={() => setIsBulkUploadClicked(true)}>
                  {t('pageOrder:bulk-update-shipment-button')}
                </Menu.Item>
              </Menu>
            }
            trigger="click"
          >
            <DownloadCSVButton
              type="primary"
              shape="round"
              icon={<ShippingFeeIcon type="button" width={22} height={24} style={{ marginTop: '-4px' }} />}
            />
          </Dropdown>
        )}
        {(isRoleAllowOperation || isRoleAllowPrintInvoiceAndDownloadBasicCSV) && (
          <Dropdown
            overlay={
              <Menu>
                {isRoleAllowPrintInvoiceAndDownloadBasicCSV && (
                  <Menu.Item icon={<TagsOutlined />} onClick={() => setIsExportCSVClicked(true)}>
                    {t('pageOrder:export-order-csv-button')}
                  </Menu.Item>
                )}
                {isRoleAllowOperation && (
                  <Menu.Item icon={<DollarCircleOutlined />} onClick={() => setIsExportPaymentsCSVClicked(true)}>
                    {t('pageOrder:export-payment-csv-button')}
                  </Menu.Item>
                )}
              </Menu>
            }
            trigger="click"
          >
            <DownloadCSVButton type="primary" shape="round" icon={<DownloadOutlined />} />
          </Dropdown>
        )}
        {(isRoleAllowOperation || isRoleAllowUpdate) && (
          <>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item icon={<MergeCellsOutlined />} onClick={onMergeOrderBtnClick}>
                    {t('pageOrder:merge-orders-button')}
                  </Menu.Item>
                  <Menu.Item icon={<SplitCellsOutlined />} onClick={onSplitOrderBtnClick}>
                    {t('pageOrder:split-orders-button')}
                  </Menu.Item>
                  {isRoleAllowOperation && (
                    <Menu.Item icon={<MergeCellsOutlined />} onClick={onMergeCustomerBtnClick}>
                      {t('pageOrder:merge-customers-button')}
                    </Menu.Item>
                  )}
                </Menu>
              }
              trigger="click"
            >
              <Button type="primary" shape="round" icon={<RetweetOutlined />}>
                {t('pageOrder:merge-split-order-button')}
              </Button>
            </Dropdown>
            <Button type="primary" shape="round" icon={<PlusOutlined />} onClick={onCheckCanCreateOrder}>
              {t('pageOrder:add-new-order-button')}
            </Button>
          </>
        )}
      </Space>
    </TitleContainer>
  );
};

const SourceSelectorRow = ({ t, orderSources, selectedSourceView, onSourceViewButtonClick }) => {
  return (
    <Row>
      <RadioGroup style={{ marginBottom: 8 }} value={selectedSourceView} optionType="button" buttonStyle="solid" onChange={onSourceViewButtonClick}>
        <RadioButton value={SOURCE_VIEW_OPTION_ALL} style={{ padding: '0 12px' }}>
          {t('common:filter-all-label')}
        </RadioButton>
        {orderSources.map(orderSource => (
          <RadioButton key={orderSource.value} value={orderSource.value} style={{ padding: '0 12px' }}>
            {orderSource.label}
          </RadioButton>
        ))}
      </RadioGroup>
    </Row>
  );
};

const TableFilterAndActionRow = ({
  t,
  isLoadingConstants,
  isRoleAllowUpdate,
  isRoleAllowPrintInvoiceAndDownloadBasicCSV,
  shouldAllowOrderUpdate,
  shouldShowFilterPendingPreserved,
  shouldShowFilterPrintedOrder,
  orderStatuses,
  selectedOrderIds,
  selectedStatusView,
  isFilterPrinted,
  isStoreOrderTagSuggestionsLoading,
  storeOrderTagsSuggestions,
  delaySetSearchTagKeyword,
  onOrderTagsChanged,
  onStatusViewButtonClick,
  onFilterPendingPreservedCheckboxChange,
  onFilterIsPrintedChanged,
  setIsCancelOrdersClicked,
  setIsUpdateOrdersClicked,
  setIsBulkPrintOrdersClicked
}) => {
  const handleOnFilterIsPrintedCheckboxChanged = isPrintedInArray => {
    const isPrinted = isPrintedInArray[isPrintedInArray.length - 1];

    onFilterIsPrintedChanged(isPrinted);
  };

  return (
    <Row style={{ marginBottom: '4px' }}>
      <Col span={24}>
        <Row justify="space-between" gutter={[0, 8]}>
          <Col>
            {!isLoadingConstants && (
              <RadioGroup value={selectedStatusView} optionType="button" buttonStyle="solid" onChange={onStatusViewButtonClick}>
                {orderStatuses.map(orderStatus => (
                  <RadioButton key={orderStatus.value} value={orderStatus.value} style={{ padding: '0 12px' }}>
                    {orderStatus.label}
                  </RadioButton>
                ))}
                <RadioButton value={STATUS_VIEW_OPTION_ALL} style={{ padding: '0 12px' }}>
                  {t('common:filter-all-label')}
                </RadioButton>
              </RadioGroup>
            )}
          </Col>
          <Col>
            <Row gutter={[8, 4]}>
              {shouldAllowOrderUpdate && isRoleAllowUpdate && (
                <>
                  <Col>
                    <Button
                      type="danger"
                      disabled={selectedOrderIds.length < 1}
                      onClick={() => setIsCancelOrdersClicked(true)}
                      icon={<DeleteOutlined />}
                    >
                      {t('pageOrder:cancel-multiple-orders', { itemCount: selectedOrderIds.length })}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      disabled={selectedOrderIds.length < 1}
                      onClick={() => setIsUpdateOrdersClicked(true)}
                      icon={<TagsOutlined />}
                    >
                      {t('pageOrder:update-multiple-orders', { itemCount: selectedOrderIds.length })}
                    </Button>
                  </Col>
                </>
              )}
              {isRoleAllowPrintInvoiceAndDownloadBasicCSV && (
                <Col>
                  <Button
                    type="primary"
                    disabled={selectedOrderIds.length < 1}
                    onClick={() => setIsBulkPrintOrdersClicked(true)}
                    icon={<PrinterOutlined />}
                  >
                    {t('pageOrder:print-multiple-orders', { itemCount: selectedOrderIds.length })}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row align="middle" style={{ marginBottom: '8px' }} gutter={[8, 8]}>
          {shouldShowFilterPendingPreserved && (
            <Col>
              <Checkbox onChange={onFilterPendingPreservedCheckboxChange}>{t('pageOrder:checkbox-label-filter-preserved')}</Checkbox>
            </Col>
          )}
          {shouldShowFilterPrintedOrder && (
            <Col>
              <CheckboxGroup value={[isFilterPrinted]} onChange={handleOnFilterIsPrintedCheckboxChanged}>
                <Checkbox key="checkbox is printed" value={true}>
                  {t('pageOrder:checkbox-label-filter-printed-orders')}
                </Checkbox>
                <Checkbox key="checkbox is non printed" value={false}>
                  {t('pageOrder:checkbox-label-filter-non-printed-orders')}
                </Checkbox>
              </CheckboxGroup>
            </Col>
          )}
          <Col flex="auto" span={24} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <PiBookmarksLight style={{ fontSize: '20px', color: '#797979' }} />
            <FormSelection
              placeholder={t('pageOrder:select-input-placeholder-order-tags')}
              isMultiple
              selections={storeOrderTagsSuggestions ? storeOrderTagsSuggestions : []}
              isSearching={isStoreOrderTagSuggestionsLoading}
              onSearch={delaySetSearchTagKeyword}
              onChange={onOrderTagsChanged}
              customMarginBottom="0"
              style={{ widht: '100%', minWidth: '320px' }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

/* =================================================== Main Component =================================================== */
const Orders = ({ store, checkCanUserAccess, storeOperatorRoles }) => {
  const { t } = useTranslation(['common', 'pageOrder']);
  const history = useHistory();

  const [query, setQuery] = useState(DEFAULT_PAGINATION_QUERY);
  const [isLoadingDiffStatusView, setIsLoadingDiffStatusView] = useState(false);
  const [isLoadingDiffSourceView, setIsLoadingDiffSourceView] = useState(false);
  const [selectedStatusView, setSelectedStatusView] = useState('');
  const [selectedSourceView, setSelectedSourceView] = useState(SOURCE_VIEW_OPTION_ALL);
  const [selectedOrderId, setSelectedOrderId] = useState('');
  const [isCancelOrderClicked, setIsCancelOrderClicked] = useState(false);
  const [isViewProductsClicked, setIsViewProductsClicked] = useState(false);
  const [isViewPaymentClicked, setIsViewPaymentClicked] = useState(false);
  const [isViewSummaryClicked, setIsViewSummaryClicked] = useState(false);
  const [isCancellingOrder, setIsCancellingOrder] = useState(false);
  const [isExportShipmentCSVClicked, setIsExportShipmentCSVClicked] = useState(false);
  const [isExportCSVClicked, setIsExportCSVClicked] = useState(false);
  const [isExportPaymentsCSVClicked, setIsExportPaymentsCSVClicked] = useState(false);
  const [isBulkUploadClicked, setIsBulkUploadClicked] = useState(false);
  const [isUpdateShippingClicked, setIsUpdateShippingClicked] = useState(false);
  const [currentPage, setCurrentPage] = useState();
  const [searchTagKeyword, setSearchTagKeyword] = useState('');
  const delaySetSearchTagKeyword = useMemo(() => debounce(setSearchTagKeyword, 300), []);

  const {
    isLoadingConstants,
    countries,
    orderStatusesConst,
    orderStatuses,
    orderSources,
    paymentMethods,
    balanceStatuses,
    productTypesConst,
    socialMediaSourcesConst
  } = useFetchConstants();

  const { isLoading: isOrdersLoading, paginatedData: orders, total, refetch: refetchOrders } = useGetOrders(query);
  const { isLoading: isOrderLoading, data: order } = useGetOrderDetails(selectedOrderId);
  const { isLoading: isShipmentsLoading, data: shipments } = useGetShipments();
  const { isLoading: isStoreOrderTagSuggestionsLoading, data: storeOrderTagsSuggestions } = useGetStoreOrderTagSuggestions(searchTagKeyword);

  const {
    isCustomerRemarksModalVisible,
    setIsCustomerRemarksModalVisible,
    selectedCustomerId: customerRemarksSelectedCustomerId,
    handleOnCustomerRemarksBtnClick
  } = useCustomerRemarksModal();
  const { isFBModalVisible, setIsFBModalVisible, selectedCustomerId, orderSummary, handleOnFBBtnClick } = useFBModal();

  const {
    isCustomerMergeModalVisible,
    handleOnMergeCustomerBtnClick: onMergeCustomerBtnClick,
    handleOnMergeCustomerModalCancel: onMergeCustomerModalCancel
  } = useCustomerMergeModal();

  const {
    isOrderMergeModalVisible,
    handleOnMergeOrderBtnClick: onMergeOrderBtnClick,
    handleOnMergeOrderModalCancel: onMergeOrderModalCancel
  } = useOrderMergeModal();

  const {
    isOrderSplitModalVisible,
    handleOnSplitOrderBtnClick: onSplitOrderBtnClick,
    handleOnSplitOrderModalCancel: onSplitOrderModalCancel
  } = useOrderSplitModal();

  const handleOnMergeCustomerSuccess = useCallback(() => {
    refetchOrders();
    onMergeCustomerModalCancel();
  }, [refetchOrders, onMergeCustomerModalCancel]);

  const handleOnMergeOrderSuccess = useCallback(() => {
    refetchOrders();
    onMergeOrderModalCancel();
  }, [refetchOrders, onMergeOrderModalCancel]);

  const handleOnSplitOrderSuccess = useCallback(() => {
    refetchOrders();
    onSplitOrderModalCancel();
  }, [refetchOrders, onSplitOrderModalCancel]);

  const { isLoading } = useIsLoadings([isLoadingConstants, isOrdersLoading, isShipmentsLoading]);
  const canCreateOrder = store.subscriptionConfig.canCreateOrder;
  const isRoleAllowOperation = checkCanUserAccess([storeOperatorRoles.ADMIN.code, storeOperatorRoles.OPERATOR.code]);
  const isRoleAllowUpdate = isRoleAllowOperation || checkCanUserAccess([storeOperatorRoles.LIVE_STREAMER.code]);
  const isRoleAllowPrintInvoiceAndDownloadBasicCSV = isRoleAllowOperation || checkCanUserAccess([storeOperatorRoles.PACKAGING.code]);
  const isEmailCheckoutLinkEnabled = guard(() => store.subscriptionConfig.isEmailCheckoutLinkEnabled, false);

  const shipmentSelections = useMemo(
    () =>
      guard(() => shipments, []).map(shipment => ({
        value: shipment._id,
        label: shipment.label
      })),
    [shipments]
  );

  const handleOnAfterBulkUpdateSuccess = useCallback(() => {
    setQuery(prevQuery => {
      return {
        ...prevQuery,
        currentPage: 1
      };
    });
    setCurrentPage(1);
    refetchOrders();
  }, [refetchOrders]);

  const {
    isCancelOrdersClicked,
    setIsCancelOrdersClicked,

    isUpdateOrdersClicked,
    setIsUpdateOrdersClicked,

    isBulkPrintOrdersClicked,
    setIsBulkPrintOrdersClicked,

    isUpdatingOrders,
    isCancellingOrders,

    selectedOrderIds,
    setSelectedOrderIds,

    handleOnCancelMultipleOrders,
    handleOnUpdateMultipleOrders,
    handleOnPrintBulkOrders
  } = useBulkActions({
    t,
    orderStatusesConst,
    selectedStatusView,
    onAfterBulkUpdateSuccess: handleOnAfterBulkUpdateSuccess
  });

  const shouldAllowOrderUpdate =
    !isLoadingConstants &&
    selectedStatusView !== orderStatusesConst.COMPLETED.code &&
    selectedStatusView !== orderStatusesConst.CANCELLED.code &&
    selectedStatusView !== STATUS_VIEW_OPTION_ALL;

  const shouldShowTablePaginationTotal = shouldAllowOrderUpdate;

  useEffect(() => {
    if (!isLoadingConstants && selectedStatusView === '') {
      setSelectedStatusView(orderStatusesConst.PENDING.code);
      setQuery({ ...DEFAULT_PAGINATION_QUERY, filter: { status: orderStatusesConst.PENDING.code } });
    }
    if (!currentPage) {
      setCurrentPage(1);
    }
    if (!isLoading && (isLoadingDiffStatusView || isLoadingDiffSourceView)) {
      setIsLoadingDiffStatusView(false);
      setIsLoadingDiffSourceView(false);
    }
  }, [
    isLoadingConstants,
    isLoading,
    isLoadingDiffStatusView,
    isLoadingDiffSourceView,
    currentPage,
    selectedStatusView,
    orderStatusesConst,
    query,
    orders,
    total
  ]);

  const handleOnBlacklistModalConfirm = useCallback(
    (sourceUserId, customerSource, shouldBlacklistUser) => () => {
      patchSocialMediaUserBlacklistStatus(sourceUserId, customerSource, shouldBlacklistUser).then(() => {
        refetchOrders();
      });
    },
    [refetchOrders]
  );

  const handleOnStatusViewButtonClick = e => {
    const statusView = e.target.value;
    setIsLoadingDiffStatusView(true);
    setSelectedStatusView(statusView);
    setSelectedOrderIds([]);
    setCurrentPage(1);
    if (statusView !== STATUS_VIEW_OPTION_ALL) {
      setQuery(prevQuery => {
        return {
          ...DEFAULT_PAGINATION_QUERY,
          filter: { status: statusView, tags: guard(() => prevQuery.filter.tags) }
        };
      });
    } else {
      setQuery(prevQuery => {
        return {
          ...DEFAULT_PAGINATION_QUERY,
          filter: { status: undefined, tags: guard(() => prevQuery.filter.tags) }
        };
      });
    }
  };

  const handleOnSourceViewButtonClick = e => {
    const sourceView = e.target.value;
    setIsLoadingDiffSourceView(true);
    setSelectedSourceView(sourceView);
    setSelectedOrderIds([]);
    setCurrentPage(1);
    if (sourceView !== STATUS_VIEW_OPTION_ALL) {
      setQuery(prevQuery => {
        return {
          ...DEFAULT_PAGINATION_QUERY,
          filter: { status: guard(() => prevQuery.filter.status), sources: sourceView, tags: guard(() => prevQuery.filter.tags) }
        };
      });
    } else {
      setQuery(prevQuery => {
        return {
          ...DEFAULT_PAGINATION_QUERY,
          filter: { status: guard(() => prevQuery.filter.status), sources: undefined, tags: guard(() => prevQuery.filter.tags) }
        };
      });
    }
  };

  const handleOnWABtnClick = contactNumber => {
    const hasPresetCustomWAMessage = !!store.customWAMessage;
    const message = encodeURIComponent(hasPresetCustomWAMessage ? `${store.customWAMessage}` : `Hi!`);
    window.open(`https://api.whatsapp.com/send?phone=${contactNumber}&text=${message}`);
    window.dataLayer.push({
      event: 'wa_event',
      action: 'wa_button_clicked'
    });
  };

  const handleOnCopyCheckoutLinkButtonClick = order => {
    const { orderNumber, accessCode } = order;
    const link = getOrderExtCheckoutLink(orderNumber, accessCode);
    navigator.clipboard.writeText(link);
    message.success(t('pageOrder:copy-success-message'));
  };

  const handleOnOpenCancelOrderModal = orderId => {
    setSelectedOrderId(orderId);
    setIsCancelOrderClicked(true);
  };

  const handleOnConfirmCancelOrder = payload => {
    setIsCancellingOrder(true);
    patchCancelOrder(selectedOrderId, payload)
      .then(() => {
        message.success(t('pageOrder:modal-cancel-order-success-message'));
        setIsCancellingOrder(false);
        setSelectedOrderId('');
        setIsCancelOrderClicked(false);
        refetchOrders();
      })
      .catch(ex => {
        message.error(t('common:update-fail-message-reason', { errMessage: ex.message }));
        setIsCancellingOrder(false);
      });
  };

  const handleOnViewPaymentBtnClick = orderId => {
    setSelectedOrderId(orderId);
    setIsViewPaymentClicked(true);
  };

  const handleOnViewSummaryBtnClick = orderId => {
    setSelectedOrderId(orderId);
    setIsViewSummaryClicked(true);
  };

  const handleOnCloseViewOrderSummaryAndProductsModal = () => {
    setIsViewSummaryClicked(false);
    setIsViewProductsClicked(false);
  };

  const handleOnUpdateShippingBtnClick = orderId => {
    setSelectedOrderId(orderId);
    setIsUpdateShippingClicked(true);
  };

  const handleOnBulkUploadSuccess = () => {
    setIsBulkUploadClicked(false);
    refetchOrders();
  };

  const handleOnCheckCanCreateOrder = () => {
    if (canCreateOrder) {
      history.push(getNewOrderRoute().path);
    } else {
      notification.warning({
        duration: 0,
        placement: 'topRight',
        message: <b style={{ color: 'red' }}>{t('pageOverview:starter-user-order-limit-exceed-notification-message')}</b>,
        btn: (
          <TextButton
            onClick={() => {
              notification.destroy();
              history.push(getSubscriptionPlanRoute().path);
            }}
            icon={null}
            text={
              <>
                {t('pageOverview:starter-user-order-limit-exceed-notification-button')} <RightOutlined />
              </>
            }
          />
        ),
        description: <p>{t('pageOverview:starter-user-order-limit-exceed-notification-desc')}</p>
      });
    }
  };

  const handleOnFilterPendingPreservedCheckboxChange = e => {
    setQuery(prevQuery => {
      return {
        ...DEFAULT_PAGINATION_QUERY,
        filter: {
          status: guard(() => prevQuery.filter.status),
          isPrinted: guard(() => prevQuery.filter.isPrinted),
          tags: guard(() => prevQuery.filter.tags),
          isPreserved: e.target.checked
        }
      };
    });
  };

  const handleOnCopyOrderSummaryBtnClick = order => {
    const orderSummary = constructOrderSummary(order, store);
    navigator.clipboard.writeText(orderSummary).then(() => {
      message.success(t('pageOrder:copy-success-message'));
    });
  };

  const handleOnFilterIsPrintedChanged = isPrinted => {
    setQuery(prevQuery => {
      return {
        ...DEFAULT_PAGINATION_QUERY,
        filter: {
          ...prevQuery.filter,
          isPrinted
        }
      };
    });
  };

  const handleOnOrderTagsChanged = tags => {
    setQuery(prevQuery => {
      return {
        ...DEFAULT_PAGINATION_QUERY,
        filter: {
          ...prevQuery.filter,
          tags
        }
      };
    });
  };

  return (
    <>
      <Helmet t={t} />
      <FullWidthContainer>
        <GeneralActionRow
          t={t}
          isRoleAllowOperation={isRoleAllowOperation}
          isRoleAllowPrintInvoiceAndDownloadBasicCSV={isRoleAllowPrintInvoiceAndDownloadBasicCSV}
          isRoleAllowUpdate={isRoleAllowUpdate}
          setIsExportShipmentCSVClicked={setIsExportShipmentCSVClicked}
          setIsExportCSVClicked={setIsExportCSVClicked}
          setIsExportPaymentsCSVClicked={setIsExportPaymentsCSVClicked}
          setIsBulkUploadClicked={setIsBulkUploadClicked}
          onMergeCustomerBtnClick={onMergeCustomerBtnClick}
          onMergeOrderBtnClick={onMergeOrderBtnClick}
          onSplitOrderBtnClick={onSplitOrderBtnClick}
          onCheckCanCreateOrder={handleOnCheckCanCreateOrder}
        />

        <SourceSelectorRow
          t={t}
          orderSources={orderSources}
          selectedSourceView={selectedSourceView}
          onSourceViewButtonClick={handleOnSourceViewButtonClick}
        />

        <TableFilterAndActionRow
          t={t}
          isLoadingConstants={isLoadingConstants}
          isRoleAllowUpdate={isRoleAllowUpdate}
          isRoleAllowPrintInvoiceAndDownloadBasicCSV={isRoleAllowPrintInvoiceAndDownloadBasicCSV}
          shouldAllowOrderUpdate={shouldAllowOrderUpdate}
          orderStatuses={orderStatuses}
          shouldShowFilterPendingPreserved={!isLoadingConstants && query?.filter?.status === orderStatusesConst.PENDING.code}
          shouldShowFilterPrintedOrder={!isLoadingConstants && query?.filter?.status !== orderStatusesConst.CANCELLED.code}
          selectedOrderIds={selectedOrderIds}
          selectedStatusView={selectedStatusView}
          isFilterPrinted={query?.filter?.isPrinted}
          isStoreOrderTagSuggestionsLoading={isStoreOrderTagSuggestionsLoading}
          storeOrderTagsSuggestions={storeOrderTagsSuggestions}
          delaySetSearchTagKeyword={delaySetSearchTagKeyword}
          onOrderTagsChanged={handleOnOrderTagsChanged}
          onStatusViewButtonClick={handleOnStatusViewButtonClick}
          onFilterPendingPreservedCheckboxChange={handleOnFilterPendingPreservedCheckboxChange}
          onFilterIsPrintedChanged={handleOnFilterIsPrintedChanged}
          setIsCancelOrdersClicked={setIsCancelOrdersClicked}
          setIsUpdateOrdersClicked={setIsUpdateOrdersClicked}
          setIsBulkPrintOrdersClicked={setIsBulkPrintOrdersClicked}
        />

        {isLoadingDiffStatusView || isLoadingDiffSourceView ? (
          <Skeleton active />
        ) : (
          <>
            {selectedOrderIds.length > 0 && (
              <p style={{ fontSize: '12px', marginBottom: 0, color: '#797979' }}>
                <i>{t('pageOrder:selected-multiple-orders', { itemCount: selectedOrderIds.length })}</i>
              </p>
            )}
            <Table
              loading={isLoading}
              size="small"
              rowKey={record => record._id}
              rowSelection={{
                type: 'checkbox',
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelectedOrderIds(selectedRowKeys);
                },
                selectedRowKeys: selectedOrderIds
              }}
              dataSource={orders}
              columns={constructColumns({
                t,
                store,
                selectedStatusView,
                orderStatuses,
                orderStatusesConst,
                shipmentSelections,
                balanceStatuses,
                socialMediaSourcesConst,
                isRoleAllowUpdate,
                handleOnWABtnClick,
                handleOnFBBtnClick,
                handleOnCustomerRemarksBtnClick,
                handleOnCopyCheckoutLinkButtonClick,
                handleOnOpenCancelOrderModal,
                handleOnViewPaymentBtnClick,
                handleOnViewSummaryBtnClick,
                handleOnUpdateShippingBtnClick,
                handleOnBlacklistModalConfirm,
                handleOnCopyOrderSummaryBtnClick
              })}
              scroll={{ x: 1200 }}
              locale={{
                triggerDesc: t('common:table-header-sort-trigger-desc'),
                triggerAsc: t('common:table-header-sort-trigger-asc'),
                cancelSort: t('common:table-header-sort-cancel-sort')
              }}
              pagination={{
                current: currentPage,
                total,
                showSizeChanger: true,
                onChange: page => setCurrentPage(page),
                ...(shouldShowTablePaginationTotal && { showTotal: formatPaginationShowTotalDisplay })
              }}
              onChange={(pagination, filters, sorter) => {
                handleOnAggregationTableChange(
                  {
                    pagination: { current: currentPage, ...pagination },
                    filters: { status: selectedStatusView !== STATUS_VIEW_OPTION_ALL && [selectedStatusView], ...query.filter, ...filters },
                    sorter
                  },
                  setQuery,
                  { remainArrayFields: ['tags'] }
                );
              }}
            />
          </>
        )}
      </FullWidthContainer>

      {isCancelOrderClicked && !isOrderLoading && (
        <CancelOrderModal
          isLoading={isCancellingOrder}
          order={order}
          onCancel={() => setIsCancelOrderClicked(false)}
          onOk={handleOnConfirmCancelOrder}
        />
      )}
      {(isViewSummaryClicked || isViewProductsClicked) && (
        <OrderSummaryAndProductsModal
          orderId={selectedOrderId}
          onCancel={handleOnCloseViewOrderSummaryAndProductsModal}
          isViewProductsOnly={isViewProductsClicked}
          storeSecurityConfig={store.securityConfig}
          storeCustomWAMessage={store.customWAMessage}
          hasInvoiceAccess={isRoleAllowPrintInvoiceAndDownloadBasicCSV}
          hasActionAccess={isRoleAllowUpdate}
          isEmailCheckoutLinkEnabled={isEmailCheckoutLinkEnabled}
        />
      )}
      {isViewPaymentClicked && <OrderViewPaymentModal store={store} orderId={selectedOrderId} onClose={() => setIsViewPaymentClicked(false)} />}
      {isUpdateOrdersClicked && (
        <StatusUpdateModal
          isMultipleOrders
          isLoading={isUpdatingOrders}
          ordersCount={selectedOrderIds.length}
          status={selectedStatusView}
          onCancel={() => setIsUpdateOrdersClicked(false)}
          onOk={handleOnUpdateMultipleOrders}
        />
      )}
      {isCancelOrdersClicked && (
        <CancelOrderModal
          isMultipleOrders
          isLoading={isCancellingOrders}
          ordersCount={selectedOrderIds.length}
          onCancel={() => setIsCancelOrdersClicked(false)}
          onOk={handleOnCancelMultipleOrders}
        />
      )}
      {isBulkPrintOrdersClicked && (
        <PrintBulkOrdersModal
          ordersCount={selectedOrderIds.length}
          onCancel={() => setIsBulkPrintOrdersClicked(false)}
          onOk={handleOnPrintBulkOrders}
        />
      )}
      {isFBModalVisible && (
        <FBModal
          isFBModalVisible={isFBModalVisible}
          setIsFBModalVisible={setIsFBModalVisible}
          selectedCustomerId={selectedCustomerId}
          storeCustomWAMessage={store.customWAMessage}
          orderSummary={orderSummary}
        />
      )}
      {isCustomerRemarksModalVisible && (
        <CustomerRemarksModal
          isCustomerRemarksModalVisible={isCustomerRemarksModalVisible}
          setIsCustomerRemarksModalVisible={setIsCustomerRemarksModalVisible}
          selectedCustomerId={customerRemarksSelectedCustomerId}
        />
      )}
      {isExportCSVClicked && (
        <ExportOrdersCSVModal
          checkCanUserAccess={checkCanUserAccess}
          storeOperatorRoles={storeOperatorRoles}
          onCancel={() => setIsExportCSVClicked(false)}
        />
      )}
      {isExportPaymentsCSVClicked && <ExportPaymentsCSVModal onCancel={() => setIsExportPaymentsCSVClicked(false)} />}
      {isExportShipmentCSVClicked && <ExportShipmentCSVModal onCancel={() => setIsExportShipmentCSVClicked(false)} />}
      {isBulkUploadClicked && <BulkUploadModal onOk={() => handleOnBulkUploadSuccess()} onCancel={() => setIsBulkUploadClicked(false)} />}
      {isCustomerMergeModalVisible && <CustomerMergeModal onMergeSuccess={handleOnMergeCustomerSuccess} onCancel={onMergeCustomerModalCancel} />}
      {isOrderMergeModalVisible && (
        <OrderMergeModal
          orderStatuses={orderStatuses}
          orderStatusesConst={orderStatusesConst}
          paymentMethods={paymentMethods}
          countries={countries}
          productTypesConst={productTypesConst}
          socialMediaSourcesConst={socialMediaSourcesConst}
          storeCustomWAMessage={store.customWAMessage}
          isEmailCheckoutLinkEnabled={isEmailCheckoutLinkEnabled}
          onMergeOrder={handleOnMergeOrderSuccess}
          onCancel={onMergeOrderModalCancel}
        />
      )}
      {isOrderSplitModalVisible && (
        <OrderSplitModal orderStatuses={orderStatuses} onSplitOrder={handleOnSplitOrderSuccess} onCancel={onSplitOrderModalCancel} />
      )}
      {isUpdateShippingClicked && <OrderUpdateShipmentModal orderId={selectedOrderId} onCancel={() => setIsUpdateShippingClicked(false)} />}
    </>
  );
};

export default withAppContext(Orders);
