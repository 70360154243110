import { useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Col, Form, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import FormInput from 'components/FormInput/FormInput';
import FormRadioButton from 'components/FormRadioButton/FormRadioButton';
import FormPasswordInput from 'components/FormPasswordInput/FormPasswordInput';
import OrderTagSelection from 'components/OrderTagSelection/OrderTagSelection';

import { useFetchConstant } from 'hooks/constants';
import { PASSCODE_REGEX } from 'utils/constants';

import { OrderNumberText } from './StatusUpdateModal.styles';

const { useForm } = Form;

const StatusUpdateModal = ({ isLoading, isMultipleOrders, order, ordersCount, onCancel, onOk, status, isRequirePasscode }) => {
  const { t } = useTranslation(['common', 'pageOrder']);
  const [form] = useForm();

  const initialValues = useMemo(() => (isMultipleOrders ? { status } : { ...order, tags: order.tags?.map(tag => tag.tagId) }), [
    isMultipleOrders,
    order,
    status
  ]);

  const [isSettleBalanceChecked, setIsSettleBalanceChecked] = useState(false);
  const [shouldShowSettleBalanceCheckbox, setShouldShowSettleBalanceCheckbox] = useState(false);

  const { data: orderStatusesConst, selection: orderStatuses, isLoading: isOrderStatusesLoading } = useFetchConstant('orderStatuses');
  const orderStatusesWOCancelled = useMemo(
    () => (isOrderStatusesLoading ? [] : orderStatuses.filter(orderStatus => orderStatus.value !== orderStatusesConst.CANCELLED.code)),
    [isOrderStatusesLoading, orderStatuses, orderStatusesConst]
  );

  useEffect(() => {
    setIsSettleBalanceChecked(shouldShowSettleBalanceCheckbox);
  }, [shouldShowSettleBalanceCheckbox]);

  const handleOnUpdateStatus = async () => {
    try {
      const payload = await form.validateFields();
      onOk({ ...payload, isSettleBalanceChecked });
    } catch (ex) {}
  };

  return (
    <Modal
      visible={!isOrderStatusesLoading}
      width={768}
      title={
        isMultipleOrders ? (
          <OrderNumberText>{t('pageOrder:modal-update-status-title', { itemCount: ordersCount || 1 })}</OrderNumberText>
        ) : (
          <OrderNumberText>{order.orderNumber}</OrderNumberText>
        )
      }
      onCancel={onCancel}
      footer={
        <>
          <Button onClick={onCancel} loading={isLoading}>
            {t('pageOrder:modal-update-status-close-modal-button-text')}
          </Button>
          <Button type="primary" onClick={handleOnUpdateStatus} loading={isLoading}>
            {t('common:form-submit-update-button-text')}
          </Button>
        </>
      }
    >
      <Form form={form} initialValues={initialValues} scrollToFirstError style={{ width: '100%' }}>
        <Row gutter={16}>
          <Col span={24}>
            <Col span={24}>
              <FormRadioButton
                label={t('pageOrderDetails:form-input-label-order-status')}
                name="status"
                selections={orderStatusesWOCancelled}
                buttonStyle="solid"
                onChange={e => {
                  setShouldShowSettleBalanceCheckbox([orderStatusesConst.PAID.code, orderStatusesConst.COMPLETED.code].includes(e.target.value));
                }}
              />
            </Col>
            <FormInput
              label={t('pageOrder:modal-update-status-form-input-label-order-remarks')}
              name="remarks"
              placeholder={
                isMultipleOrders
                  ? t('pageOrder:modal-update-status-form-input-placeholder-multiple-order-remarks')
                  : t('pageOrder:modal-update-status-form-input-placeholder-order-remarks')
              }
              type="textArea"
              rows={8}
            />
            <Col span={24} style={{ marginBottom: '24px' }}>
              <OrderTagSelection name="tags" isAllowNew />
            </Col>
            {isRequirePasscode && (
              <FormPasswordInput
                label={t('pageOrder:modal-update-status-passcode-label')}
                name="securityPasscode"
                placeholder={t('pageOrder:modal-update-status-passcode-placeholder')}
                requiredErrorMessage={t('pageOrder:modal-update-status-passcode-required-error-message')}
                customPattern={PASSCODE_REGEX}
                customPatternErrorMessage={t('pageOrder:modal-update-status-passcode-invalid-pattern-error-message')}
              />
            )}
          </Col>
          {shouldShowSettleBalanceCheckbox && (
            <Checkbox
              onChange={() => setIsSettleBalanceChecked(!isSettleBalanceChecked)}
              defaultValue={isSettleBalanceChecked}
              value={isSettleBalanceChecked}
              checked={isSettleBalanceChecked}
            >
              {t('pageOrder:modal-update-status-checkbox-label')}
            </Checkbox>
          )}
        </Row>
      </Form>
    </Modal>
  );
};

export default StatusUpdateModal;
